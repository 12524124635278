import React, { Component } from 'react';
import './index.scss';
import logo from '../../image/logo.png';
import { backstagePath } from '../../util/config';
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePath: props.activePath,
      menu: [
        {
          name: '首页',
          path: '/',
          is_active: !1,
          child: [],
        },
        {
          name: '行业解决方案',
          is_showChild: !1,
          is_active: !1,
          child: [
            {
              name: '数字农业电商',
              path: '/ecommerce',
              is_active: !1,
            },
            {
              name: '数字生态农业',
              path: '/agriculture',
              is_active: !1,
            },
            {
              name: '数字运营工具',
              path: '/tool',
              is_active: !1,
            },
            {
              name: '数字乡村旅游',
              path: '/travel',
              is_active: !1,
            },
          ],
        },
        {
          name: '会员管理',
          is_showChild: !1,
          is_active: !1,
          child: [
            {
              name: '智慧客服系统',
              path: '/service',
              is_active: !1,
            },
            {
              name: '智慧会员系统',
              path: '/member',
              is_active: !1,
            },
            {
              name: '智慧收银系统',
              path: '/cashier',
              is_active: !1,
            },
          ],
        },
        {
          name: '渠道加盟',
          path: '/join',
          is_active: !1,
          child: [],
        },
        {
          name: '公司动态',
          path: '/news',
          is_active: !1,
          child: [],
          inner: [
            {
              name: '公司动态详情',
              path: '/newsdetail',
              is_active: !1,
            },
          ],
        },
        {
          name: '联系我们',
          path: '/connect',
          is_active: !1,
          child: [],
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ activePath: nextProps.activePath });
  }
  render() {
    return (
      <header className="header">
        <div className="wrap-center">
          <div className="menu">
            <img src={logo} alt="" />
            <nav>
              <ul>
                {this.state.menu.map((item, index) => {
                  return (
                    <li
                      className={this.handelMenuSelectedStatus(item, 1)}
                      style={{
                        color: this.handelMenuSelectedStatus(item, 2),
                      }}
                      onClick={() => this.switchMenu(item, index)}
                      onMouseMove={() => this.moveMenu(item, index)}
                      onMouseOut={() => this.outMenu(item, index)}
                      key={index}
                    >
                      {item.name}
                      {item.child.length ? (
                        <i
                          className="iconfont icon-arrow-down"
                          style={{
                            transform: this.handelMenuSelectedStatus(item, 3),
                            color: this.handelMenuSelectedStatus(item, 4),
                          }}
                        ></i>
                      ) : (
                        ''
                      )}
                      <ul style={{ maxHeight: item.child.length && item.is_showChild ? '150px' : 0 }}>
                        {item.child.map((childItem, childIndex) => {
                          return (
                            <li
                              style={{
                                color: this.handelMenuSelectedStatus(childItem, 5),
                              }}
                              onClick={() => this.props.handelSwitchMenu(childItem.path)}
                              onMouseMove={() => this.moveChildMenu(index, childIndex)}
                              onMouseOut={() => this.outChildMenu(index, childIndex)}
                              key={childIndex}
                            >
                              {childItem.name}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div className="login">
            <span onClick={() => window.open(backstagePath)}>注册登录</span>
            <span></span>
            <span onClick={() => window.open(backstagePath)}>免费体验</span>
          </div>
        </div>
      </header>
    );
  }
  handelMenuSelectedStatus(item, type) {
    let result = null;
    let activePath = this.state.activePath;
    let menuIndex = item.child && item.child.findIndex(row => row.path === activePath);
    let innerIndex = item.inner && item.inner.findIndex(row => activePath.indexOf(row.path) !== -1);
    if (type === 1 || type === 2) {
      if (item.path === activePath || item.is_active || menuIndex >= 0 || innerIndex >= 0) {
        result = type === 1 ? 'menu-active' : '#0A54EF';
      }
    } else if (type === 3) {
      if (item.path === activePath || item.is_active) result = 'rotateZ(-180deg)';
    } else if (type === 4) {
      if (item.path === activePath || item.is_active || menuIndex >= 0) result = '#0A54EF';
    } else if (type === 5) {
      if (item.path === activePath || item.is_active) result = '#0A54EF';
    }
    return result;
  }
  switchMenu(val) {
    if (val.path) {
      this.setState({
        activePath: val.path,
      });
      this.props.handelSwitchMenu(val.path);
    }
  }
  moveMenu(val, index) {
    let { menu } = this.state;
    if (val.child.length) {
      menu[index].is_showChild = !0;
    }
    menu[index].is_active = !0;
    this.setState({
      menu: menu,
    });
  }
  outMenu(val, index) {
    let { menu } = this.state;
    if (val.child.length) {
      menu[index].is_showChild = !1;
      this.setState({
        menu: menu,
      });
    }
    menu[index].is_active = !1;
    this.setState({
      menu: menu,
    });
  }
  moveChildMenu(index, childIndex) {
    let { menu } = this.state;
    menu[index].child[childIndex].is_active = !0;
    this.setState({
      menu: menu,
    });
  }
  outChildMenu(index, childIndex) {
    let { menu } = this.state;
    menu[index].child[childIndex].is_active = !1;
    this.setState({
      menu: menu,
    });
  }
}
