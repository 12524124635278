import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { index } from '../../util/image';
import { Carousel } from 'antd';
import staticData from './data';
import BtnJump from '../../components/btnJump';
class Index extends Component {
  constructor(props) {
    super(props);
    staticData.data2.map(item => item.top = '70px')
    staticData.data5.map(item => item.top = '70px')
    this.state = {
      ruralData: staticData.data2,
      winData: staticData.data5,
      agriculture_type: 0,
      is_cancelScroll: props.is_cancelScroll,
      agriculture_info: {
        icon: index.farmland_icon,
        name: '农田认养',
        info: {
          img: index.farmland_img,
          englishName: 'Farmland adoption',
          introduction: '农田认养解决方案，用户可以认领农田，在线领养了以后，农场开始为你养殖。',
          key: {
            name1: '查看土地：',
            name2: '选择地块：',
            name3: '确认订单：',
          },
          otherinfo1: '可查看土地相关信息、实时监控、土壤温湿度、土壤电导率等一系列实时数据',
          otherinfo2: '选择地块、选择租赁周期、选择自己需求的地块服务',
          otherinfo3: '添加地块选择种植的种子和配送信息，填写租赁人相关信息，立即支付',
        },
      },
      travel_type: 0,
      travel_info: {
        name: '田园养老',
        introduction: '用户可亲身体验生态绿色产品的制作，设施条件可以满足对不同农产品个性化制作的需求。',
        back_img: index.travel_back1,
        img: index.travel_img1,
      },
      numData: {
        year: 0,
        family: 0,
        second: 0,
        time: 0,
      },
      banner: [],
      news: [],
      agriculture_lock: !0,
      agriculture_info_lock: !0,
      tool_lock: !0,
      soft_lock: !0,
      numData_lock: !0,
      news_lock: !0,
      support_lock: !0
    };
  }
  render() {
    return (
      <>
        <Carousel autoplay>
          {this.state.banner.map((item, index) => {
            return (
              <div className="banner-list" onClick={() => window.open(item.link)} key={index}>
                <img src={item.picture} alt="" />
              </div>
            );
          })}
        </Carousel>
        <div className="num-type">
          <ul className="wrap-center ">
            {staticData.data1.map((item, index) => {
              return (
                <li key={index}>
                  <img src={item.icon} alt="" />
                  <div className="con">
                    <p>{item.title}</p>
                    <p>{item.introduction}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="rural">
          <div className="commerce">
            <div className="wrap-center">
              <div className="title">乡村振兴：数字化农业电商</div>
              <ul>
                {this.state.ruralData.map((item, index) => {
                  return (
                    <li key={index} style={{
                      top: item.top
                    }}>
                      <div
                        className="cover"
                        style={{
                          background: 'url(' + item.cover + ')no-repeat',
                          backgroundSize: '100% 100%',

                        }}
                      >
                        {item.title}
                      </div>
                      <div className="content">{item.content}</div>
                      <div className="more">了解更多</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="agriculture">
            <div id="agriculture" className={this.state.agriculture_lock ? '' : 'animate-show'}>
              <div className="title">乡村振兴：数字化农业电商</div>
              <p>认养农业解决方案，涵盖了农业领域农田养认、养果树、认养家畜、畜牧、水产等，认养过程中消费者视屏监控可看消费者与农场共同管理。</p>
              <ul>
                {staticData.data3.map((item, index) => {
                  return (
                    <li onClick={() => this.selectAgricultureInfo(item, index)} key={index}>
                      <img src={item.icon} alt="" />
                      <span
                        className={this.state.agriculture_type === index ? 'agriculture-span-active' : ''}
                        style={{
                          color: this.state.agriculture_type === index ? '#0A54EF' : '',
                        }}
                      >
                        {item.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="agriculture_info">
            <div id="agriculture_info" className={`wrap-center content ${this.state.agriculture_info_lock ? '' : 'animate-show'}`}>
              <div className="img">
                <img src={this.state.agriculture_info.info.img} alt="" />
              </div>
              <div className="info">
                <div className="englishName">{this.state.agriculture_info.info.englishName}</div>
                <div className="name">{this.state.agriculture_info.name}</div>
                <div className="introduction">{this.state.agriculture_info.info.introduction}</div>
                <div className="more-info">
                  <div>
                    <span>{this.state.agriculture_info.info.key.name1}</span>
                    {this.state.agriculture_info.info.otherinfo1}
                  </div>
                  <div>
                    <span>{this.state.agriculture_info.info.key.name2}</span>
                    {this.state.agriculture_info.info.otherinfo2}
                  </div>
                  <div>
                    <span>{this.state.agriculture_info.info.key.name3}</span>
                    {this.state.agriculture_info.info.otherinfo3}
                  </div>
                </div>
                <div className="tryout">
                  <BtnJump icon={index.jiantou} text="免费试用"></BtnJump>
                </div>
              </div>
            </div>
          </div>
          <Carousel ref="travel" dots={false} effect="fade">
            {
              staticData.data4.map((item, index) => {
                return (
                  <div
                    className="travel"
                    key={index}
                  >
                    <img className="back" src={item.back_img} alt="" />
                    <div className="mask" style={{ display: index == 0 ? 'block' : 'none' }}></div>
                    <div className="content">
                      <div className="travel-type">
                        <div className="travel-type-back"></div>
                        <ul>
                          {
                            staticData.data4.map((item, index) => {
                              return (
                                <li
                                  className={index === this.state.travel_type ? 'travel-type-active' : ''}
                                  style={{
                                    background: index === this.state.travel_type ? 'linear-gradient(90deg, #37394A, #1D2028)' : '',
                                  }}
                                  onClick={() => this.switchTravel(index)}
                                  key={index}
                                >
                                  {item.name}
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="travel-info">
                        <div className="title">乡村振兴：数字乡村旅游</div>
                        <div className="name">{item.name}</div>
                        <div className="introduction">{item.introduction}</div>
                        <div className="more">
                          <BtnJump icon={index.jiantou} text="了解更多"></BtnJump>
                        </div>
                        <div className="img">
                          <img src={item.img} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </Carousel>
          <div className="tool">
            <div id="tool" className={this.state.tool_lock ? '' : 'animate-show'}>
              <div className="title">乡村振兴：数字运营工具</div>
              <div className="wrap-center content">
                <img className="img" src={index.tool_img} alt="" />
                <div className="tool-info">
                  <ul>
                    <li>
                      <img src={index.tool_icon_kefu} alt="" />
                      <div className="tool-name">
                        <p>智慧客服系统</p>
                        <p>连接顾客，高效客服工作台，大幅提升客户满意度！</p>
                      </div>
                    </li>
                    <li>
                      <img src={index.tool_icon_xitong} alt="" />
                      <div className="tool-name">
                        <p>智慧会员系统</p>
                        <p>通过店铺数据、会员数据、智能标签精准勾勒会员画像，多维度 深挖会员价值！</p>
                      </div>
                    </li>
                    <li>
                      <img src={index.tool_icon_store} alt="" />
                      <div className="tool-name">
                        <p>智连门店</p>
                        <p>以软硬件结合的方式实现精细的门店数字化管理，赋能门店私域 运营和线上成交，助力门店开拓新零售业务场景与数智化经营！</p>
                      </div>
                    </li>
                    <li>
                      <img src={index.tool_icon_shouyin} alt="" />
                      <div className="tool-name">
                        <p>高效的简洁的收银工具</p>
                        <p>连接顾客，高效简单、易用收银工作台，大幅提升客户满意度！</p>
                      </div>
                    </li>
                  </ul>
                  <BtnJump icon={index.jiantou} text="了解更多"></BtnJump>
                </div>
              </div>
            </div>
          </div>
          <div id="win" className="wrap-center win-win">
            <div className="title">乡村振兴：多方共赢</div>
            <ul>
              {
                this.state.winData.map((item, index) => {
                  return (
                    <li style={{ top: item.top }} key={index}>
                      <div
                        className="cover"
                      >
                        <img src={item.img} alt="" />
                        <span>{item.title}</span>
                      </div>
                      <p>{item.text1}</p>
                      <p>{item.text2}</p>
                      {item.text3 && <p>{item.text3}</p>}
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className="soft" style={{
          background: 'url(' + index.soft_back + ')no-repeat',
          backgroundSize: '100% 100%',
        }}>
          <div id="soft" className={this.state.soft_lock ? '' : 'animate-show'}>
            <div className="title">获得多项商标、软件著作权，专业实力备受认可</div>
            <div className="introduction">强大的技术研发团队，为数字化乡村电商保驾护航</div>
            <img src={index.soft_img} alt="" />
            <BtnJump icon={index.jiantou} text="了解更多"></BtnJump>
          </div>

        </div>
        <div
          className="num-back"
          id="num-back"
          style={{
            background: 'url(' + index.num_back + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div id="num-data" className={`wrap-center num-data ${this.state.numData_lock ? '' : 'animate-show'}`}>
            <div>
              <div>
                <p>
                  {this.state.numData.year}
                  <span>年</span>
                </p>
                <p>研发迭代</p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  {this.state.numData.family}
                  <span>+家</span>
                </p>
                <p>信赖之选</p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  {this.state.numData.second}
                  <span>毫秒</span>
                </p>
                <p>服务平均响应</p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  {this.state.numData.time}
                  <span>小时</span>
                </p>
                <p>稳定服务</p>
              </div>
            </div>
          </div>
        </div>
        {this.state.news.length && (
          <div id="news" className={`news wrap-center ${this.state.news_lock ? '' : 'animate-show'}`}>
            <div className="title">公司动态</div>
            <div className="news-con">
              <div className="first-news" onClick={() => this.goDetail(this.state.news[0].id)}>
                <img className="cover" src={this.state.news[0].thumbImage} alt="" />
                <div className="titles">{this.state.news[0].title}</div>
                <img className="line" src={index.news_line} alt="" />
                <div className="date">
                  <span>{this.state.news[0].publishTime.slice(0, 10)}</span>
                  <span>查看全文</span>
                </div>
              </div>
              <ul>
                {this.state.news.slice(1, 6).map((item, i) => {
                  return (
                    <li onClick={() => this.goDetail(item.id)} key={i}>
                      <img src={index.news_triangle} alt="" />
                      <span>{item.publishTime.slice(0, 10)}</span>
                      <div>{item.title}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <div
          className="support"
          style={{
            background: 'url(' + index.support_back + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <ul id="support" className={`wrap-center ${this.state.support_lock ? '' : 'animate-show'}`}>
            <li>
              <img src={index.support_icon1} alt="" />
              <span>移动化多终端支持</span>
            </li>
            <li>
              <img src={index.support_icon2} alt="" />
              <span>免安装云端加密存储</span>
            </li>
            <li>
              <img src={index.support_icon3} alt="" />
              <span>免费更新升级</span>
            </li>
            <li>
              <img src={index.support_icon4} alt="" />
              <span>免费更新升级</span>
            </li>
          </ul>
        </div>
      </>
    );
  }
  componentDidMount() {
    this.handelPageScroll();
    this.getBanner();
    this.getNews();
    this.ruralAnimation();
  }

  ruralAnimation() {
    let ruralData = this.state.ruralData;
    ruralData[0].top = 0;
    this.setState({
      ruralData
    })
    setTimeout(() => {
      ruralData[1].top = 0;
      this.setState({
        ruralData
      })
    }, 250)
    setTimeout(() => {
      ruralData[2].top = 0;
      this.setState({
        ruralData
      })
    }, 500)
    setTimeout(() => {
      ruralData[3].top = 0;
      this.setState({
        ruralData
      })
    }, 750)
  }
  getBanner() {
    this.$axios.post('/page/protal').then(res => {
      if (res.code == 0) {
        res.result.push(...res.result)
        this.setState({
          banner: res.result,
        });
      }
    });
  }
  getNews() {
    this.$axios
      .post('/WebsiteArticle/list', {
        page: 1,
        rows: 6,
      })
      .then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.setState({
            news: list,
          });
        }
      });
  }
  switchTravel(index) {
    this.setState({
      travel_type: index,
    })
    this.refs.travel.innerSlider.slickGoTo(index)
  }
  handelPageScroll() {
    let that = this;
    let num_lock = !0;
    let win_lock = !0;
    let { agriculture_lock, agriculture_info_lock, tool_lock, soft_lock, numData_lock, news_lock, support_lock } = this.state;
    that.props.setCancelScroll(0);
    const handel = function () {
      if (that.props.is_cancelScroll) {
        return window.removeEventListener('scroll', handel, false);
      }
      let news = that.state.news.length;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let targetNumTop = document.getElementById('num-back').offsetTop;
      let targetWinTop = document.getElementById('win').offsetTop;
      let targetAgricultureTop = document.getElementById('agriculture').offsetTop;
      let targetAgricultureInfoTop = document.getElementById('agriculture_info').offsetTop;
      let toolTop = document.getElementById('tool').offsetTop;
      let softTop = document.getElementById('soft').offsetTop;
      let numDataTop = document.getElementById('num-data').offsetTop;
      let supportTop = document.getElementById('support').offsetTop;
      if (news) {
        var newsTop = document.getElementById('news').offsetTop;
      }
      if (num_lock) {
        if (targetNumTop < windowHeight + scrollTop - 150) {
          num_lock = !1;
          that.handelNumData();
        }
      }
      if (win_lock) {
        if (targetWinTop < windowHeight + scrollTop - 180) {
          win_lock = !1;
          that.handelWinData();
        }
      }
      if (agriculture_lock) {
        if (targetAgricultureTop < windowHeight + scrollTop) {
          agriculture_lock = !1;
          that.setState({
            agriculture_lock: agriculture_lock
          })
        }
      }
      if (agriculture_info_lock) {
        if (targetAgricultureInfoTop < windowHeight + scrollTop) {
          agriculture_info_lock = !1;
          that.setState({
            agriculture_info_lock: agriculture_info_lock
          })
        }
      }
      if (tool_lock) {
        if (toolTop < windowHeight + scrollTop) {
          tool_lock = !1;
          that.setState({
            tool_lock: tool_lock
          })
        }
      }
      if (soft_lock) {
        if (softTop < windowHeight + scrollTop) {
          soft_lock = !1;
          that.setState({
            soft_lock: soft_lock
          })
        }
      }
      if (numData_lock) {
        if (numDataTop < windowHeight + scrollTop) {
          numData_lock = !1;
          that.setState({
            numData_lock: numData_lock
          })
        }
      }
      if (news_lock && news) {
        if (newsTop < windowHeight + scrollTop) {
          news_lock = !1;
          that.setState({
            news_lock: news_lock
          })
        }
      }
      if (support_lock) {
        if (supportTop < windowHeight + scrollTop) {
          support_lock = !1;
          that.setState({
            support_lock: support_lock
          })
        }
      }
    };
    window.addEventListener('scroll', handel, false);
  }
  goDetail(id) {
    this.props.setCancelScroll(1)
    document.documentElement.scrollTop = 0;
    setTimeout(() => {
      this.props.history.push('/newsdetail/' + id)
    }, 200)
  }
  handelNumData() {
    let that = this;
    let numData = this.state.numData;
    let year = setInterval(() => {
      numData.year++;
      that.setState({
        numData,
      });
      if (numData.year === 6) {
        clearInterval(year);
      }
    }, 3000 / 6);
    let family = setInterval(() => {
      numData.family += Math.floor(50000 / 300);
      if (numData.family > 50000) {
        numData.family = 50000;
      }
      that.setState({
        numData,
      });
      if (numData.family >= 50000) {
        clearInterval(family);
      }
    }, 10);

    let time = setInterval(() => {
      numData.time += Math.floor(52560 / 300);
      if (numData.time > 52560) {
        numData.time = 52560;
      }
      that.setState({
        numData,
      });
      if (numData.time >= 52560) {
        clearInterval(time);
      }
    }, 10);
    let second = setInterval(() => {
      numData.second++;
      that.setState({
        numData,
      });
      if (numData.second === 200) {
        clearInterval(second);
      }
    }, 3000 / 200);
  }
  handelWinData() {
    let winData = this.state.winData;
    winData[0].top = 0;
    this.setState({
      winData
    })
    setTimeout(() => {
      winData[1].top = 0;
      this.setState({
        winData
      })
    }, 250)
    setTimeout(() => {
      winData[2].top = 0;
      this.setState({
        winData
      })
    }, 500)
    setTimeout(() => {
      winData[3].top = 0;
      this.setState({
        winData
      })
    }, 750)
  }
  selectAgricultureInfo(val, index) {
    this.setState({
      agriculture_info: val,
      agriculture_type: index,
    });
  }
  updateName() {
    this.props.updateName();
  }
}

function mapStateToProps(state) {
  return {
    is_cancelScroll: state.is_cancelScroll,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setCancelScroll(val) {
      dispatch({
        type: 'setCancelScroll',
        data: val,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
