import React, { Component } from 'react';
import Banner from '../../../components/banner';
import { plan } from '../../../util/image';
import Bigdata from "../../../components/bigdata";
import './index.scss';
export default class Tool extends Component {
  render() {
    return (
      <>
        <Banner plate="3" back_img={plan.banner3} title="数字运营工具" introduction="快速捕获客户线索构建独特的自动化的交互体验，并通过营销反馈数据及时调优，提升客户满意度与线索转化率"></Banner>
        <div className="tools">
          <div className="wrap-center content">
            <div className="title">数字运营工具</div>
            <ul>
              <li>
                <div className="top">
                  <img src={plan.icon1} alt="" />
                  <span>新客引流</span>
                </div>
                <div className="bot">彩蛋系统获客引流，提供基于多生态的全套SCRM策略， 并配合营销自动化解决方案，致力于帮助品牌降低人力 成本、提高转化、助力品牌将粉丝转化为客户。</div>
              </li>
              <li>
                <div className="top">
                  <img src={plan.icon2} alt="" />
                  <span>促销神器</span>
                </div>
                <div className="bot">通过小程序直播，视频号直播带货，拼团，秒杀，抢 购，分销等多社区电商时代营销利器，迅速提升品牌 影响力，帮助品牌吸引线上粉丝获取海量用户，快速 扩张传播卖货。</div>
              </li>
              <li>
                <div className="top">
                  <img src={plan.icon3} alt="" />
                  <span>引导复购</span>
                </div>
                <div className="bot">线上线下联合发力，多种玩法灵活营销，以用户体验为 核心，延展触发用户粘性，引导复购，促进订单达成， 提升成单率。</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="process wrap-center">
          <div className="title">数字运营流程图</div>
          <div className="content">
            <div className="item">
              <div className="name">
                <img className="icon" src={plan.icon4} alt="" />
                <div>
                  <p>全渠道数</p>
                  <p>据采集</p>
                </div>
              </div>
              <img className="jiantou" src={plan.icon8} alt="" />
            </div>
            <div className="item">
              <div className="name">
                <img className="icon" src={plan.icon5} alt="" />
                <div>
                  <p>数据处理</p>
                  <p>构建画像</p>
                </div>
              </div>
              <img className="jiantou" src={plan.icon8} alt="" />
            </div>
            <div className="item">
              <div className="name">
                <img className="icon" src={plan.icon6} alt="" />
                <div>
                  <p>精细化营</p>
                  <p>销培育潜在客户</p>
                </div>
              </div>
              <img className="jiantou" src={plan.icon8} alt="" />
            </div>
            <div className="item">
              <div className="name">
                <img className="icon" src={plan.icon7} alt="" />
                <div>
                  <p>数据核查</p>
                  <p>调整运营方向</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="func"
          style={{
            background: 'url(' + plan.func_back + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div className="name">产品功能</div>
          <div className="introduction">快速捕获客户线索构建独特的自动化的交互体验，并通过营销反馈数据及时调优，提升客户满意度与线索转化率</div>
          <img src={plan.func} alt="" />
        </div>
        <div className="wrap-center system-admin">
          <div className="title">系统化管理客户</div>
          <div className="introduction">客户画像帮您了解客户，会员卡/储值卡实现精准营销</div>
          <ul>
            <li>
              <div className="img">
                <img src={plan.cover1} alt="" />
              </div>
              <div className="text">
                <div className="name">
                  <span></span>
                  智慧收银
                </div>
                <p>让运营更简单，更高效。支持多门店、多业态，以系 统集成方式更好满足门店客户的发展需要，打造未来 数字化乡村电商门店系统。</p>
              </div>
            </li>
            <li>
              <div className="img">
                <img src={plan.cover2} alt="" />
              </div>
              <div className="text">
                <div className="name">
                  <span></span>
                  智慧客服
                </div>
                <p>
                  多渠道部署，满足多样化需求，可接入微信公众号、 小程序、H5，多渠道部署，实现客服统一回复和处 理。可设置自动回复、快捷语回复、客服管理等功 能，提升客服工作能力，提高工作效率，让您的用
                  户获得最优质的服务。
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                <img src={plan.cover3} alt="" />
              </div>
              <div className="text">
                <div className="name">
                  <span></span>
                  智连门店
                </div>
                <p>以软硬件结合的方式实现精细的门店数字化管理，赋 能门店私域运营和线上成交，助力门店开拓新零售业 务场景与数智化经营</p>
              </div>
            </li>
          </ul>
        </div>
        <Bigdata></Bigdata>
      </>
    );
  }
}
