import React, { Component } from "react";
import style from "./index.module.scss";
import f_logo from "../../image/f_logo.png";
import gongan from "../../image/gongan.png";
import phone from "../../image/phone.png";
import address from "../../image/address.png";
import email from "../../image/email.png";
import code from "../../image/code.jpg";
import { Image } from 'antd';
import { Link } from "react-router-dom";
export default class Footer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <footer>
        <div className={`wrap-center ${style['footer-con']}`}>
          <div className={style.top}>
            <div className={style.left}>
              <img className={style.f_logo} src={f_logo} alt="" />
              <div className={style.phone}>
                <img src={phone} alt="" />
                <span>400-2689-388</span>
              </div>
              <div className={style.email}>
                <img src={email} alt="" />
                <span>3654624@224.com</span>
              </div>
              <div className={style.address}>
                <img src={address} alt="" />
                <span>深圳市宝安区宗泰绿凯智汇园310</span>
              </div>
            </div>
            <div className={style.right}>
              <div className={style.plan}>
                <div className={style.title}>行业解决方案</div>
                <div className={style.item}>
                  <Link to="/ecommerce">数字农业电商</Link>
                </div>
                <div className={style.item}>
                  <Link to="/agriculture">数字生态农业</Link>
                </div>
                <div className={style.item}>
                  <Link to="/tool">数字运营工具</Link>
                </div>
                <div className={style.item}>
                  <Link to="/travel">数字乡村旅游</Link>
                </div>
              </div>
              <div className={style.vip}>
                <div className={style.title}>会员管理</div>
                <div className={style.item}>
                  <Link to="/member">智慧会员系统</Link>
                </div>
                <div className={style.item}>
                  <Link to="/cashier">智慧收银系统</Link>
                </div>
                <div className={style.item}>
                  <Link to="/service">智慧客服系统</Link> 
                </div>
              </div>
              <div className={style.support}>
                <div className={style.title}>客户支持</div>
                <div className={style.item}>
                  <Link to="/join">渠道加盟</Link>
                </div>
                <div className={style.item}>
                  <Link to="/connect">联系我们</Link>
                </div>
                <div className={style.item}>
                  <Link to="/helpCenterList">帮助中心</Link>
                </div>
              </div>
              <div className={style.code}>
                <div className={style.title}>彩蛋数字公众号</div>
                <div className={style.item}>
                  <Image
                    width={76}
                    height={76}
                    src={code}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={style.bot}>
            <p>版权所有彩蛋数字 &copy;深圳市微搜天下科技有限公司 <span onClick={() => window.open('https://beian.miit.gov.cn/')}>粤ICP备2021121871号</span></p>
            <div>
              <img src={gongan} alt="" />
              <span>粤公网安备 43019002000847号</span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
