import React, { Component } from "react";
import "./index.scss";
import { index } from "../../util/image";
import { BackTop } from "antd";
import kf_code from "../../image/kf_code.png"
import code from "../../image/code.jpg"
export default class Right extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_wx: false,
      is_connect: false,
      is_thepublic: false
    }
  }
  render() {
    return (
      <div className="right-fixed">
        <div
          className="title"
          style={{
            background: "url(" + index.hezuo + ")no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          合作咨询
        </div>
        <div className="connect" onClick={(e) => this.show(1,e)}>
          <img src={index.dianhua} alt="" />
          <span>电话联系</span>
          {
            this.state.is_connect && <div className="lianxi">
              <p>专属客服为您接听</p>
              <p>18138827686</p>
            </div>
          }

        </div>
        <div className="wx" onClick={(e) => this.show(2,e)}>
          <img src={index.weixin} alt="" />
          <span>微信联系</span>
          {
            this.state.is_wx && <div className="code">
              <img src={kf_code} alt="" />
              <span>官方微信客服</span>
            </div>
          }

        </div>
        <div className="thepublic" onClick={(e) => this.show(3,e)}>
          <img src={index.erweima} alt="" />
          <span>公众号</span>
          {
            this.state.is_thepublic && <div className="code">
              <img src={code} alt="" />
              <span>官方微信公众号</span>
            </div>
          }
        </div>
        <BackTop>
          <div className="top">
            <img src={index.zhiding} alt="" />
            <span>返回顶部</span>
          </div>
        </BackTop>
      </div>
    );
  }
  componentDidMount() {
    let that = this;
    document.body.onclick = function () {
      that.setState({
        is_wx: false,
        is_connect: false,
        is_thepublic: false
      })
    }
  }
  show(type,e) {
    e.stopPropagation()
    if (type == 1) {
      this.setState({
        is_wx: false,
        is_connect: true,
        is_thepublic: false
      })
    } else if (type == 2) {
      this.setState({
        is_wx: true,
        is_connect: false,
        is_thepublic: false

      })
    } else {
      this.setState({
        is_wx: false,
        is_connect: false,
        is_thepublic: true
      })
    }
  }
}
