import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plan } from '../../util/image';
import "./index.scss";
class Bigdata extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num: {
                text1: 0,
                text2: 0,
                text3: 0,
                text4: 0
            }
        };
    }
    render() {
        return (
            <div
                className="big-data"
                id="big-data"
                style={{
                    background: 'url(' + plan.data_back + ')no-repeat',
                    backgroundSize: '100% 100%',
                }}
            >
                <div className="title">大数据+云安全为彩蛋系统提供强大的技术支持</div>
                <ul className="wrap-center">
                    <li>
                        <div>
                            <div className="num">
                                <img src={plan.icon9} alt="" /> {this.state.num.text1}+
                            </div>
                            <div className="name">合作商户</div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className="num">
                                <img src={plan.icon9} alt="" /> {this.state.num.text2}+
                            </div>
                            <div className="name">产品迭代</div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className="num">
                                <img src={plan.icon9} alt="" /> {this.state.num.text3}+
                            </div>
                            <div className="name">数据埋点</div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className="num">
                                <img src={plan.icon9} alt="" /> {this.state.num.text4}+
                            </div>
                            <div className="name">优质装修模板</div>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
    componentDidMount() {
        this.handelPageScroll();
    }
    handelPageScroll() {
        let that = this;
        let num_lock = !0;
        that.props.setCancelScroll(0);
        const handel = function () {
            if (that.props.is_cancelScroll) {
                return window.removeEventListener('scroll', handel, false);
            }
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            let targetDiv = document.getElementById('big-data').offsetTop;
            if (num_lock) {
                if (targetDiv < windowHeight + scrollTop - 170) {
                    num_lock = !1;
                    that.handelNumData();
                }
            }
        };
        window.addEventListener('scroll', handel, false);
    }
    handelNumData() {
        let that = this;
        let num = this.state.num;
        let text1 = setInterval(() => {
            num.text1 += Math.round(50000 / 300);
            if (num.text1 > 50000) {
                num.text1 = 50000;
            }
            that.setState({
                num,
            });
            if (num.text1 >= 50000) {
                clearInterval(text1);
            }
        }, 10);
        let text2 = setInterval(() => {
            num.text2 += Math.round(500 / 300)
            if (num.text2 > 500) {
                num.text2 = 500;
            }
            that.setState({
                num,
            });
            if (num.text2 >= 500) {
                clearInterval(text2);
            }
        }, 10);
        let text3 = setInterval(() => {
            num.text3 += Math.ceil(1000 / 300);
            if (num.text3 > 1000) {
                num.text3 = 1000;
            }
            that.setState({
                num,
            });
            if (num.text3 >= 1000) {
                clearInterval(text3);
            }
        }, 3000 / 200);
        let text4 = setInterval(() => {
            num.text4++;
            that.setState({
                num,
            });
            if (num.text4 >= 100) {
                clearInterval(text4);
            }
        }, 3000 / 100);
    }
}


function mapStateToProps(state) {
    return {
        is_cancelScroll: state.is_cancelScroll,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        setCancelScroll(val) {
            dispatch({
                type: 'setCancelScroll',
                data: val,
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bigdata);

