import React, { Component } from 'react';
import './index.scss';
import { backstagePath } from '../../util/config';
import { index } from '../../util/image';
export default class btnJump extends Component {
  render() {
    return (
      <div className="jump-btn" onClick={()=> window.open(backstagePath)}>
        <span>{this.props.text}</span>
        <img src={index.jiantou || this.props.icon} alt="" />
      </div>
    );
  }
}
