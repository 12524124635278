import React, { Component } from "react";
import "./index.scss";
import { cashier } from "../../../../util/image";
import { backstagePath } from '../../../../util/config';
export default class Apply extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="apply">
        <img src={cashier.bj03} alt="bj03" />
        <div className="apply_text1">迎接现代数字化业务，您准备好了吗？</div>
        <div className="apply_text2" onClick={() => window.open(backstagePath)}>申请体验</div>
      </div>
    );
  }
}
