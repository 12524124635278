import React, { Component } from "react";
import "./index.scss";
import { backstagePath } from '../../../../util/config';
import { inspectPhone } from '../../../../util/utils';
import { message } from 'antd';
import CountUp from 'react-countup';
export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
        contact:''
    };
  }
  submit() {
    if (inspectPhone(this.state.contact)) {
      this.$axios
        .post('/Contact/commit', {
          contact: this.state.contact,
          plate: this.props.plate,
        })
        .then(res => {
          if (res.code == 0) {
            window.open(backstagePath);
          }
        });
    }else{
      message.warning("请输入正确手机号格式")
    }
  }
  render() {
    return (
      <div className="banner2">
        <img className="banner_image" src={this.props.back_img} alt="banner" />
        <div className="banner_top">
          <div className="banner_text1">{this.props.title}</div>
          <div className="banner_text2">{this.props.introduction}</div>
          <div className="input">
            <input type="text" placeholder="请输入您的手机号" value={this.state.contact} onChange={e => this.setState({ contact: e.target.value })} />
            <div onClick={() => this.submit()}>立即免费试用</div>
          </div>
        </div>
        <div className="banner_bottom">
          <div className="part1">
            <div className="text1">
              <span>{<CountUp end={6} start={0} duration={0.8}/>}</span>年
            </div>
            <div className="text2">研发迭代</div>
          </div>
          <div className="part2">
            <div className="text1">
              <span>{<CountUp end={50000} start={0} duration={2.5}/>}</span>+家
            </div>
            <div className="text2">信赖之选</div>
          </div>
          <div className="part3">
            <div className="text1">
              <span>{<CountUp end={200} start={0} duration={1.4} />}</span>毫秒
            </div>
            <div className="text2">服务平均响应</div>
          </div>
          <div className="part4">
            <div className="text1">
              <span>{<CountUp end={52560} start={0} duration={2.6}/>}</span>小时
            </div>
            <div className="text2">稳定服务</div>
          </div>
        </div>
      </div>
    );
  }
}
