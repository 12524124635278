
import "./index.scss";
import Search from "../components/search";
import React, { useState, useEffect } from 'react';
import { message, Pagination, Empty } from 'antd';
import axios from "../../../util/axios";
export default function HelpCenter() {
    const [helpTypeList, setHelpTypeList] = useState([]);
    const [helpList, setHelpList] = useState([]);
    const [total, setTotal] = useState(0);
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [helpInfo, setHelpInfo] = useState(null);
    const [state, setState] = useState({
        typeName: '全部',
        title: '',
        category: '',
        page: 1,
        rows: 8,
    })
    let getHelpType = () => {
        axios.post('/WebsiteArticleCase/list', {
            rows: 10000
        })
            .then(res => {
                if (res.code == 0) {
                    let list = res.result.list;

                    list.map(item => {
                        item.is_open = !1;
                        item.is_select = !1;
                        item.child.list.map(child => child.is_select = !1);
                    })
                    list.unshift({
                        child: {
                            list: []
                        },
                        title: '全部',
                        id: 0,
                        is_open: !1,
                        is_select: !0
                    })
                    setHelpTypeList(list)
                }
            });
    }
    let getHelpList = () => {
        axios.post('/WebsiteArticle/helpList', {
            title: state.title,
            category: state.category,
            page: state.page,
            rows: state.rows
        }).then(res => {
            if (res.code == 0) {
                setHelpList(res.result.list)
                setTotal(res.result.total_number)
            } else {
                message.error(res.msg)
            }
        })
    }
    let handleHelpTypeParent = (row, index) => {
        let data = JSON.parse(JSON.stringify(helpTypeList));
        if (row.child.list.length) {
            data[index].is_open = !data[index].is_open;
        } else {
            data.map(item => {
                item.is_select = !1
                item.child.list.map(child => child.is_select = !1)
            })
            data[index].is_select = !0;
            setState({
                ...state,
                typeName: row.title,
                category: row.id
            })
        }
    
        setHelpTypeList(data)
    }
    let handleHelpTypeChild = (row, index, y) => {
        let data = JSON.parse(JSON.stringify(helpTypeList));
        data.map(item => {
            item.is_select = !1
            item.child.list.map(child => child.is_select = !1)
        })
        data[index].child.list[y].is_select = !0;
        setHelpTypeList(data)
        setState({
            ...state,
            typeName: row.title,
            category: row.id
        })
    }
    let getHelpInfo = (row) => {
        setHelpInfo(row)
    }
    let onChange = (val) => {
        setState({
            ...state,
            page: val
        })
    }
    useEffect(() => {
        getHelpList();
        setHelpInfo(null)
    }, [state])
    useEffect(() => {
        getHelpType();
    }, [])
    return (
        <>
            <Search state={state} setState={setState}></Search>
            <div className="help-con wrap-center">
                <div className="help-type">
                    {
                        helpTypeList.map((item, index) => {
                            return (
                                <div className="collapse" key={item.id}>
                                    <div className="collapse-item" style={{ color: item.is_select ? '#0a54ef' : '' }} onClick={() => handleHelpTypeParent(item, index)}>
                                        <div>{item.title}</div>
                                        <i className="iconfont icon-arrow-down" style={{ transform: item.is_open ? 'rotateZ(-180deg)' : '', display: item.child.list.length ? 'block' : 'none' }}></i>
                                    </div>
                                    <div>
                                        {
                                            item.child.list.length && item.is_open ? item.child.list.map((child, y) => {
                                                return (
                                                    <div className="collapse-item-level2" style={{ color: child.is_select ? '#0a54ef' : '' }} key={child.id} onClick={() => handleHelpTypeChild(child, index, y)}>{child.title}</div>
                                                )
                                            }) : ''
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    helpInfo ?
                        <div className="help-content">
                            <h1>{helpInfo.title}</h1>
                            <div className="content" dangerouslySetInnerHTML={{ __html: helpInfo.content }}></div>
                        </div> : <div className="help-list">
                            <div className="help-type-name">{state.typeName}</div>
                            <div className="help-box">
                                {
                                    helpList.length ? <div className="help-title">
                                        {
                                            helpList.map((item, index) => {
                                                return (
                                                    <div className="help-title-item" key={index}>
                                                        <span onClick={() => getHelpInfo(item)}>{item.title}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : <Empty />
                                }
                                <div className="help-pagination">
                                    <Pagination pageSize={state.rows} current={state.page} total={total} onChange={(e) => onChange(e)} />
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}