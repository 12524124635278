import React, { Component } from "react";
import { member } from "../../../util/image";
import Banner from "../components/banner";
import Apply from "../components/apply";
import Logo from "../components/logo";
import './index.scss'
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      part1data: [
        {
          icon: member.icon1,
          title: '公域临时顾客转为私域资产',
          text: '智能化引导沉睡顾客，电商，微博，日常到店顾客沉淀为微信顾客，低成本快速扩大私域顾客池',
          hasline: false,
        },
        {
          icon: member.icon2,
          title: '构建线上私域平台，随时触达顾客',
          text: '小程序+公众号+个人号，打造会自动营销，智能激活，随时裂变的线上流量池',
          hasline: true,
        },
        {
          icon: member.icon3,
          title: '精准画像助力精细化运营',
          text: '顾客标签化管理，多维度构建顾客画像，挖掘顾客中高价值KOL资源并可精准触达',
          hasline: true,
        },
        {
          icon: member.icon4,
          title: '线上服务增强流量池粘性',
          text: '顾客可以自助查询服务并评价，可以自助预约等，多元化互动构建粉丝强关系',
          hasline: true,
        },
        {
          icon: member.icon5,
          title: '快速配置会员权益，分级维护顾客',
          text: '智能发卡，自动升降卡级，不同卡级匹配不同积分和折扣权益',
          hasline: false,
        },
        {
          icon: member.icon6,
          title: '搭建智能营销体系',
          text: '优惠券体系，可以有效激活顾客，提升顾客复购率',
          hasline: true,
        },
        {
          icon: member.icon7,
          title: '提高顾客订单单价和复购',
          text: '现金红包和支付有礼可以有效激活客户的购物欲，提升顾客的复购率。',
          hasline: true,
        },
        {
          icon: member.icon8,
          title: '海量增强模块，按需增加',
          text: '回访管理，呼叫中心，短信支持，在线客服，拼团，分销，异业合作，小蓝杯等模块，满足你的所有会员管理需求。',
          hasline: true,
        },
      ],
      case: [],
    };
  }
  componentDidMount() {
    this.getCase();
  }
  getCase() {
    this.$axios.post("/WebsiteCase/list").then(res => {
      if (res.code == 0) {
        this.setState({
          case: res.result.slice(0, 4)
        })
      }
    })
  }
  render() {
    return (
      <div className="member">
        <div className="part1">
          <div className="part1_title">彩蛋智慧会员系统助力企业数字化转型</div>
          <div className="part1_flex">
            {this.state.part1data.map((item, index) => {
              return (
                <div
                  className={`part1_flex_item ${item.hasline ? "hasline" : ""}`}
                  key={index}
                >
                  <img src={item.icon} alt="icon" />
                  <div className="part1_flex_item_title">{item.title}</div>
                  <div className="part1_flex_item_text">{item.text}</div>
                </div>
              );
            })}
            <div></div>
          </div>
        </div>
        <div className="part2">
          <img src={member.banner2} alt="banner2" className="part2_bj" />
          <div className="part2_title">会员资产数据化的价值</div>
          <img src={member.img1} alt="img1" className="part2_img1" />
        </div>
        <div className="part3">
          <div className="part3_title">会员资产数据化的价值</div>
          <img src={member.img2} alt="img2" />
          <div className="part3_item part3_item1">
            <div className="part3_item_title">
              <div>01</div>
              <div>会员资产管理</div>
            </div>
            <div className="part3_item_text">
              全渠道数据的收集和处理，线上多平台、线上线下数据打通，建立统一的会员忠诚度体系，会员资产数据化管理。
            </div>
          </div>
          <div className="part3_item part3_item2">
            <div className="part3_item_title">
              <div>02</div>
              <div>会员精准营销</div>
            </div>
            <div className="part3_item_text">
              整合全域会员数据，搭建完整CRM运营体系，提供会员管理、精准营销、场景营销数据分析等整套的解决方案。
            </div>
          </div>
          <div className="part3_item part3_item3">
            <div className="part3_item_title">
              <div>03</div>
              <div>积分体系搭建</div>
            </div>
            <div className="part3_item_text">
              通过会员积分体系建立，实现全域会员权益等级积分互通。积分互动玩法深度挖掘会员价值提升会员粘性。
            </div>
          </div>
          <div className="part3_item part3_item4">
            <div className="part3_item_title">
              <div>04</div>
              <div>私域流量运营</div>
            </div>
            <div className="part3_item_text">
              提供从拉新引流、运营、变现整套私域流量运营解决方案。打造商家自有私域流量池，流量变现。
            </div>
          </div>
        </div>
        <div className="part4">
          <div className="part4_title">他们都选择彩蛋智慧会员系统</div>
          <div className="case">
            {this.state.case.map((item, index) => {
              return (
                <div className="case_item" key={index}>
                  <div className="case_img">
                    <img
                      src={item.image}
                      alt="case"
                    />
                    <div>
                      <img
                        src={item.logo}
                        alt="case"
                      />
                    </div>
                  </div>
                  <img
                    src={item.qrcode}
                    alt="case"
                    className="case_code"
                  />
                  <div className="case_text">{item.name}</div>
                </div>
              );
            })}

          </div>
        </div>
      </div>
    );
  }
}

export default class layout extends Component {
  render() {
    return (
      <div>
        <Banner
          plate="6"
          back_img={member.banner}
          title="彩蛋智慧会员系统"
          introduction="通过店铺数据、会员数据、智能标签精准勾勒会员画像，多维度深挖会员价值"
        ></Banner>
        <Main></Main>
        <Logo></Logo>
        <Apply></Apply>
      </div>
    );
  }
}
