import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../header';
import Footer from '../footer';
import Right from '../right';
import Index from '../../views/index';
import Connect from '../../views/connect';
import Agriculture from '../../views/plan/agriculture';
import Ecommerce from '../../views/plan/ecommerce';
import Tool from '../../views/plan/tool';
import Travel from '../../views/plan/travel';
import News from '../../views/news';
import Newsdetail from '../../views/news/details';
import Join from '../../views/join';
import Service from '../../views/management/service';
import Member from '../../views/management/member';
import Cashier from '../../views/management/cashier';
import HelpCenter from '../../views/helpCenter';
import HelpCenterList from '../../views/helpCenter/list';
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePath: this.props.location.pathname,
    };
  }
  render() {
    return (
      <>
        <Header activePath={this.state.activePath} handelSwitchMenu={this.handelSwitchMenu.bind(this)}></Header>
        <section style={{ paddingTop:  '60px' }}>
          <HashRouter>
            <Route path="/" exact component={Index}></Route>
            <Route path="/agriculture" component={Agriculture}></Route>
            <Route path="/ecommerce" component={Ecommerce}></Route>
            <Route path="/tool" component={Tool}></Route>
            <Route path="/travel" component={Travel}></Route>
            <Route path="/service" component={Service}></Route>
            <Route path="/member" component={Member}></Route>
            <Route path="/cashier" component={Cashier}></Route>
            <Route path="/join" component={Join}></Route>
            <Route path="/news" component={() => this.createNews.bind(this)()}></Route>
            <Route path="/newsdetail/:id" component={Newsdetail}></Route>
            <Route path="/connect" component={Connect}></Route>
            <Route path="/helpCenter" component={HelpCenter}></Route>
            <Route path="/helpCenterList" component={HelpCenterList}></Route>
          </HashRouter>
        </section>
        <Footer handelSwitchMenu={this.handelSwitchMenu.bind(this)}></Footer>
        {this.state.activePath != '/helpCenter' ? <Right></Right> : ''}
      </>
    );
  }
  handelSwitchMenu(path) {
    if (path !== this.state.activePath) {
      document.documentElement.scrollTop = 0;
      this.props.setCancelScroll(1);
      this.props.history.push(path);
    }
  }
  handleDetails(id) {
    document.documentElement.scrollTop = 0;
    this.props.history.push('/newsdetail/' + id);
  }
  createNews() {
    return <News handleDetails={id => this.handleDetails(id)}></News>;
  }
  componentDidMount() {
    this.props.history.listen(location => {
      this.props.setCancelScroll(1);
      this.setState({
        activePath: location.pathname,
      });
    });
  }
}


function mapDispatchToProps(dispatch) {
  return {
    setCancelScroll(val) {
      dispatch({
        type: 'setCancelScroll',
        data: val,
      });
    },
  };
}
export default connect(null, mapDispatchToProps)(Layout);
