import { HashRouter, Route } from "react-router-dom";
import Layout from "./components/layout";
function App() {
  return (
    <>
      <HashRouter>
        <Route path="/" component={Layout}></Route>
      </HashRouter>
    </>
  );
}

export default App;
