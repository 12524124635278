import React, { Component } from 'react';
import Banner from '../../../components/banner';
import { plan, index } from '../../../util/image';
import BtnJump from '../../../components/btnJump';
import './index.scss';
export default class Travel extends Component {
  render() {
    return (
      <>
        <Banner plate="4" back_img={plan.banner4} title="数字乡村旅游" introduction="数字化绿色健康乡村旅游"></Banner>
        <div className="travel-list">
          {data.map((item, i) => {
            return i % 2 ? (
              <div className="item" key={i}>
                <div className="content">
                  <div className="name">
                    <span>{item.englishName}</span>
                    {item.name}
                  </div>
                  <div className="introduction">{item.introduction}</div>
                  <BtnJump icon={index.jiantou} text="免费试用"></BtnJump>
                </div>
                <img className="img" src={item.img} alt="" />
              </div>
            ) : (
              <div className="item" key={i}>
                <img className="img" src={item.img} alt="" />
                <div className="content">
                  <div className="name">
                    <span>{item.englishName}</span> {item.name}
                  </div>
                  <div className="introduction">{item.introduction}</div>
                  <BtnJump icon={index.jiantou} text="免费试用"></BtnJump>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
let data = [
  {
    img: plan.chufang,
    name: '共享厨房',
    englishName: 'SHARED KITCHEN',
    introduction: '用户可亲身体验生态绿色产品的制作，设施条件可以满足对不同农产品个性化制作的需求。',
  },
  {
    img: plan.rencai,
    name: '体验人文风貌',
    englishName: 'HUMANISTIC STYLE',
    introduction: '乡村承载着历史的变迁和时代的风貌。乡村的每一个角落，每一天都有不同的故事。',
  },
  {
    img: plan.qingzi,
    name: '亲子旅游',
    englishName: 'SHARED KITCHEN',
    introduction: '家长带着小孩一起旅行，建构良好的亲子互动关系，亲身体验乡村生活，让让心与心贴得更近!',
  },
  {
    img: plan.tianyuan,
    name: '田园养老',
    englishName: 'RURAL ELDERLY CARE',
    introduction: '在农村养老，享受田园风光，节假日，儿女带着孙儿到乡下与他们共度周末，既提高了养老生活质量，又不妨碍享受天伦之乐。',
  },
];
