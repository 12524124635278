import React, { Component } from 'react';
import Banner from '../../../components/banner';
import { plan, index } from '../../../util/image';
import './index.scss';
import staticData from '../data';
import { Carousel } from 'antd';
import BtnJump from '../../../components/btnJump';
import { backstagePath } from "../../../util/config";
import Bigdata from "../../../components/bigdata";
export default class Ecommerce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      case: [],
      platformIndex: null,
    };
  }
  render() {
    return (
      <>
        <Banner plate="1" back_img={plan.banner1} title="数字农业电商" introduction="助力乡村数字化，进入数字化农业电商新时代"></Banner>
        <div className="funs-list">
          <div className="title">功能强大的数字农业电商系统</div>
          <div className="wrap-center">
            <Carousel autoplay>
              <div className="item">
                <ul>
                  {staticData.func1.map((item, index) => {
                    return (
                      <li key={index}>
                        <img src={item.icon} alt="" />
                        <span>{item.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="item">
                <ul>
                  {staticData.func2.map((item, index) => {
                    return (
                      <li key={index}>
                        <img src={item.icon} alt="" />
                        <span>{item.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Carousel>
          </div>
        </div>
        <div className="wrap-center case-list">
          <div className="title">他们都选择电商系统</div>
          <ul>
            {this.state.case.map((item, index) => {
              return (
                <li onMouseMove={() => this.mouseMoveCase(index)} onMouseOut={() => this.mouseOutCase(index)} key={index}>
                  <div className="info">
                    <img className="img" src={item.image} alt="" />
                    <div className="logo">
                      <img src={item.logo} alt="" />
                    </div>
                    <div className="content">
                      <p>{item.name}</p>
                      <p>{item.website_desc}</p>
                    </div>
                  </div>
                  <div
                    className="code"
                    style={{
                      transform: item.is_move ? 'scale(1)' : 'scale(0)',
                    }}
                  >
                    <div className="img">
                      <img src={item.qrcode} alt="" />
                    </div>
                    <div className="name">{item.name}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className="component"
          style={{
            background: 'url(' + plan.component.back + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div className="wrap-center content">
            <div className="title">3分钟搭建乡村移动商城</div>
            <div className="introduction">拥有完整开店系统，数十种装修组件，上百套行业模板，满足搭建需求，帮助商家快速搭建 属于自己的个性化独立商城。</div>
            <ul>
              {staticData.components.map((item, i) => {
                return (
                  <li key={i}>
                    <img src={item.icon} alt="" />
                    <span>{item.name}</span>
                  </li>
                );
              })}
            </ul>
            <BtnJump icon={index.jiantou} text="免费试用"></BtnJump>
          </div>
        </div>
        <div
          className="platform-drainage"
          style={{
            background: 'url(' + plan.platform.back + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div className="wrap-center content">
            <div className="title">聚合流量 曝光引流</div>
            <div className="introduction">微信、抖音、支付宝、百度、华为服务号、快应用联盟等多个平台，一键开店，统一管理，全方位引流</div>
            <div className="platform">
              <div className="left">
                <div className={`platform-item ${this.state.platformIndex === 0 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(0)}>
                  <div className="logo">
                    <img src={plan.platform.icon1} alt="" />
                    {this.state.platformIndex === 0 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">快应用联盟</div>
                  <p>九大厂商共同推出的新型应用生态</p>
                </div>
                <div className={`platform-item ${this.state.platformIndex === 1 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(1)}>
                  <div className="logo">
                    <img src={plan.platform.icon2} alt="" />
                    {this.state.platformIndex === 1 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">抖音小程序</div>
                  <p>视频带货，视觉冲击强</p>
                </div>
                <div className={`platform-item ${this.state.platformIndex === 2 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(2)}>
                  <div className="logo">
                    <img src={plan.platform.icon3} alt="" />
                    {this.state.platformIndex === 2 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">百度小程序</div>
                  <p>开放式赋能，精准连接用户</p>
                </div>
              </div>
              <div className="center">
                <div className={`platform-item ${this.state.platformIndex === 3 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(3)}>
                  <div className="logo">
                    <img src={plan.platform.icon4} alt="" />
                    {this.state.platformIndex === 3 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">微信小程序</div>
                  <p>自带社交属性，流量触手可及</p>
                </div>
                <div className={`platform-item ${this.state.platformIndex === 4 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(4)}>
                  <div className="logo">
                    <img src={plan.platform.icon5} alt="" />
                    {this.state.platformIndex === 4 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">支付宝小程序</div>
                  <p>多端开放，中心化频道开放</p>
                </div>
              </div>
              <div className="right">
                <div className={`platform-item ${this.state.platformIndex === 5 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(5)}>
                  <div className="logo">
                    <img src={plan.platform.icon6} alt="" />
                    {this.state.platformIndex === 5 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">QQ小程序</div>
                  <p>连接新生代用户的新方式</p>
                </div>
                <div className={`platform-item ${this.state.platformIndex === 6 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(6)}>
                  <div className="logo">
                    <img src={plan.platform.icon7} alt="" />
                    {this.state.platformIndex === 6 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">高德小店</div>
                  <p>让天下没有难找的店铺</p>
                </div>
                <div className={`platform-item ${this.state.platformIndex === 7 ? 'platform-active' : ''}`} onClick={() => this.selectdPlatform(7)}>
                  <div className="logo">
                    <img src={plan.platform.icon8} alt="" />
                    {this.state.platformIndex === 7 ? <div onClick={()=>this.go()}>免费试用</div> : ''}
                  </div>
                  <div className="name">华为服务号</div>
                  <p>华为统一的商家服务阵地</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="illustration">
          <div className="func-introduction">
            <div className="wrap-center content">
              <img src={plan.illustration1} alt="" />
              <div className="info">
                <div className="name">系统化管理客户</div>
                <p>
                  客户画像帮您了解客户，会员卡/储值卡实现精准营销；会员系统，覆盖 会员生命全周期，助力会员营销的每—环节。简洁直观的界面设计，便
                  于快速掌握，高效管理客户数据;全源道触达的自动化营销，促进品牌业 务增长;各种可视化报告，营销效果一目了然。
                </p>
                <BtnJump icon={index.jiantou} text="了解更多"></BtnJump>
              </div>
            </div>
          </div>
          <div className="func-introduction">
            <div className="wrap-center content">
              <div className="info">
                <div className="name">智慧收银</div>
                <p>让运营更简单，更高效。支持多门店、多业态，以系统集成方式更好满足 门店客户的发展需要，打造未来数字化乡村电商门店系统。</p>
                <BtnJump icon={index.jiantou} text="了解更多"></BtnJump>
              </div>
              <img src={plan.illustration2} alt="" />
            </div>
          </div>
          <div className="func-introduction">
            <div className="wrap-center content">
              <img src={plan.illustration3} alt="" />
              <div className="info">
                <div className="name"> 智慧客服</div>
                <p>
                  多渠道部署，满足多样化需求，可接入微信公众号、小程序、H5，多渠道 部署，实现客服统一回复和处理。可设置自动回复、快捷语回复等功能， 提升客服工作能力与效率，让您的用户获得最优质的服务。
                </p>
                <BtnJump icon={index.jiantou} text="了解更多"></BtnJump>
              </div>
            </div>
          </div>
        </div>
        <Bigdata></Bigdata>
      </>
    );
  }
  componentDidMount() {
    this.getCase();
  }
  go(){
    window.open(backstagePath)
  }
  getCase() {
    this.$axios.post('/WebsiteCase/list').then(res => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          case: res.result.slice(0, 8),
        });
      }
    });
  }
  mouseMoveCase(index) {
    let list = this.state.case;
    list[index].is_move = !0;
    this.setState({
      case: list,
    });
  }
  mouseOutCase(index) {
    let list = this.state.case;
    list[index].is_move = !1;
    this.setState({
      case: list,
    });
  }
  selectdPlatform(index) {
    this.setState({
      platformIndex: index,
    });
  }
}
