import React, { Component } from "react";
import "./index.scss";
import { connect } from "../../util/image";
import { inspectPhone } from '../../util/utils';
import Banner from "../../components/banner";
import { Cascader ,message} from 'antd';
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        customer_name: '',
        industry: '',
        province_id: '',
        city_id: '',
        area_id: '',
        email: '',
        name: '',
        mobile: '',
        requirement_description: '',
      },
      isSubmit: false,
      options: [],
      defaultValue:[]
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getAreaList();
  }
  componentWillUnmount() {
    this.setState = () => false;
  }
  submit() {
      if (inspectPhone(this.state.form.mobile)) {
        const form = this.state.form;
        this.$axios.post('/Contact/contactUs', form).then(res => {
          if (res.code == 0) {
            this.setState({
              isSubmit: true,
            });
          } else {
            message.warning(res.msg);
          }
        });
      } else {
        message.warning('请输入正确手机号格式');
      }
  }
  handleChange(event) {
    let form = this.state.form;
    form[event.target.name] = event.target.value;
    this.setState({ form: form });
  }
  close() {
     this.setState({
       isSubmit: false,
     });
     window.location.reload();
  }
  getAreaList(pid = 0) {
    this.$axios.post('/Contact/areaList', { pid }).then(res => {
      if (res.code == 0) {
        let list = res.result.list;
        list.forEach(item => {
          item.isLeaf = false;
        });
        this.setState({
          options: list,
        });
      }
    });
  }
  render() {
    const optionLists = this.state.options;

    const LazyOptions = () => {
      const [options, setOptions] = React.useState(optionLists);
      const onChange = value => {
        this.state.form.province_id = value[0] ? value[0] : '';
        this.state.form.city_id = value[1] ? value[1] : '';
        this.state.form.area_id = value[2] ? value[2] : '';
        this.state.defaultValue = value
      };

      const loadData = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        // load options lazily
        this.$axios.post('/Contact/areaList', { pid: targetOption.id }).then(res => {
          if (res.code == 0) {
            targetOption.loading = false;
            let list = res.result.list;
            list.forEach(item => {
              item.isLeaf = selectedOptions.length==2?true:false;
            });
            targetOption.children = list;
            setOptions([...options]);
          }
        });

      };

      const fieldNames = {
        label: 'name',
        value: 'id',
        children: 'children',
      };

      function filter(inputValue, path) {
        return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
      }
      const defaultValue = this.state.defaultValue;

      return (
        <Cascader
          options={options}
          key={options}
          loadData={loadData}
          onChange={onChange}
          className="right"
          placeholder="所在区域"
          showSearch={{ filter }}
          fieldNames={fieldNames}
          defaultValue={defaultValue}
        />
      );
    };
    return (
      <div className="main">
        <div className="text1">联系我们</div>
        <div className="text2">感谢您使用彩蛋数字系统，请您认真填写以下信息，提交成功后，我们的专业服务人员会尽快与您取得联系！</div>
        <form>
          <div className="form">
            <div className="input_item">
              <span className="star">*</span>
              <input type="text" placeholder="客户名称" name="customer_name" onChange={this.handleChange}/>
            </div>
            <div className="input_item">
              <span className="star">*</span>
              <input type="text" placeholder="所属行业" name="industry" onChange={this.handleChange} />
            </div>
            <div className="input_item">
              <span className="star">*</span>
              <LazyOptions />
            </div>
            <div className="input_item">
              <span className="star">*</span>
              <input type="text" placeholder="客户邮箱" name="email" onChange={this.handleChange} />
            </div>
            <div className="input_item">
              <span className="star">*</span>
              <input type="text" placeholder="客户联系人" name="name" onChange={this.handleChange} />
            </div>
            <div className="input_item">
              <span className="star">*</span>
              <input type="text" placeholder="客户手机号" name="mobile" onChange={this.handleChange} />
            </div>
            <div className="input_item input_item2">
              <span className="star">*</span>
              <textarea placeholder="需求描述" name="requirement_description" onChange={this.handleChange}></textarea>
            </div>
          </div>
          <div className="submit" onClick={this.submit.bind(this)}>
            提交表单
            <img src={connect.arrow_right} alt="arrow" />
          </div>
        </form>
        <div className="message" style={{ display: this.state.isSubmit ? 'flex' : 'none' }}>
          <div className="message_text1">提交成功，我们会尽快与您取得联系!</div>
          <div className="iknow" onClick={this.close.bind(this)}>
            我知道了
          </div>
        </div>
      </div>
    );
  }
}

export default class layout extends Component {
  render() {
    return (
      <div>
        <Banner
          back_img={connect.banner}
          title={null}
          introduction={null}
        ></Banner>
        <Main></Main>
      </div>
    );
  }
}
