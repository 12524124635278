import React, { Component } from 'react';
import style from './index.module.scss';
import { backstagePath } from '../../util/config';
import { inspectPhone, getDateformat } from '../../util/utils';
import { message, Modal, Table } from 'antd';

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: '',
      visible: false,
      dataSource: [],
    };
  }
  render() {
    const columns = [
      {
        title: '客户姓名',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '联系电话',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: '公司名称',
        dataIndex: 'company_name',
        key: 'company_name',
      },
      {
        title: '合作描述',
        dataIndex: 'company_introduction',
        key: 'company_introduction',
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: text => <span>{getDateformat(text)}</span>,
      },
    ];
    const dataSource = this.state.dataSource;
    return (
      <div className={style.banner}>
        <img className={style.banner_image} src={this.props.back_img} alt="banner" />
        <div className={style.banner_text1} style={{ display: this.props.title ? 'block' : 'none' }}>
          {this.props.title}
        </div>
        <div className={style.banner_text2} style={{ display: this.props.introduction ? 'block' : 'none' }}>
          {this.props.introduction}
        </div>
        <div className={style.input} style={{ display: this.props.title && !this.props.issearch ? 'flex' : 'none' }}>
          <input value={this.state.contact} onChange={e => this.setState({ contact: e.target.value })} type="text" placeholder="请输入您的手机号" />
          <div onClick={() => this.submit()}>立即免费试用</div>
        </div>
        <div className={style.input} style={{ display: this.props.issearch ? 'flex' : 'none' }}>
          <input value={this.state.contact} onChange={e => this.setState({ contact: e.target.value })} type="text" placeholder="请输入手机号" />
          <div onClick={() => this.queryChannel()}>渠道查询</div>
        </div>
        <Modal title="渠道查询" centered visible={this.state.visible} width={1000} footer={null} onCancel={() => this.onCancel()}>
          <Table dataSource={dataSource} columns={columns} />
        </Modal>
      </div>
    );
  }

  submit() {
    if (inspectPhone(this.state.contact)) {
      this.$axios
        .post('/Contact/commit', {
          contact: this.state.contact,
          plate: this.props.plate,
        })
        .then(res => {
          if (res.code == 0) {
            window.open(backstagePath);
          }
        });
    } else {
      message.warning('请输入正确手机号格式');
    }
  }
  queryChannel() {
    this.$axios.post('/Channel/query', { keywords: this.state.contact }).then(res => {
      if (res.code == 0) {
        let list = res.result.list
        list.forEach(item => {
          item.key = item.id
        });
        this.setState({
          visible: true,
          dataSource: list,
        });
      }
    });
  }
  onCancel() {
    this.setState({
      visible: false
    });
  }
}
