import React, { Component } from 'react';
import Banner from '../../../components/banner';
import { plan } from '../../../util/image';
import staticData from '../../index/data';
import BtnJump from '../../../components/btnJump';
import './index.scss';
export default class Agriculture extends Component {
  render() {
    return (
      <>
        <Banner plate="2" back_img={plan.banner2} title="数字生态农业" introduction="认养农业解决方案，涵盖了农业领域农田养认、养果树、认养家畜、畜牧、水产等"></Banner>
        <div className="wrap-center agriculture_list">
          {staticData.data3.map((item, index) => {
            return (
              <div className="agriculture_info">
                <div className="wrap-center content">
                  {index % 2 ? (
                    <>
                      <div className="info">
                        <div className="englishName">{item.info.englishName}</div>
                        <div className="name">{item.name}</div>
                        <div className="introduction">{item.info.introduction}</div>
                        <div className="more-info">
                          <div>
                            <span>{item.info.key.name1}</span>
                            {item.info.otherinfo1}   
                          </div>
                          <div>
                            <span>{item.info.key.name2}</span>
                            {item.info.otherinfo2}
                          </div>
                          <div>
                            <span>{item.info.key.name3}</span>
                            {item.info.otherinfo3}
                          </div>
                        </div>
                        <div className="tryout">
                          <BtnJump icon={index.jiantou} text="免费试用"></BtnJump>
                        </div>
                      </div>
                      <div className="img">
                        <img src={item.info.img} alt="" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="img">
                        <img src={item.info.img} alt="" />
                      </div>
                      <div className="info">  
                        <div className="englishName">{item.info.englishName}</div>
                        <div className="name">{item.name}</div>
                        <div className="introduction">{item.info.introduction}</div>
                        <div className="more-info">
                          <div>
                            <span>{item.info.key.name1}</span>
                            {item.info.otherinfo1}
                          </div>
                          <div>
                            <span>{item.info.key.name2}</span>
                            {item.info.otherinfo2}
                          </div>
                          <div>
                            <span>{item.info.key.name3}</span>
                            {item.info.otherinfo3}
                          </div>
                        </div>
                        <div className="tryout">
                          <BtnJump icon={index.jiantou} text="免费试用"></BtnJump>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
