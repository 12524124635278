import "./index.scss";
import { useState } from "react";
import banner from "../../../../image/search_banner.png";
import search from "../../../../image/search.png"
export default function HelpCenter(props) {
    let { setState } = props;
    let [title, setTitle] = useState('')
    return (
        <>
            <div className="search" style={{
                background: 'url(' + banner + ')no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <h1 className="title">hi,欢迎使用帮助中心</h1>
                <div className="search-input">
                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
                    <button onClick={() => setState({ ...props.state, title: title })}>
                        <img src={search} alt="" />
                    </button>
                </div>
            </div>
        </>
    )
}