import React, { Component } from "react";
import { connect,plan ,index} from '../../util/image';
import { inspectPhone} from '../../util/utils';
import Banner from "../../components/banner";
import { imgUrl } from "../../util/config";
import './index.scss'
import { message } from 'antd';
let logo = [];
for (let i = 0; i < 18; i++) {
  logo.push(imgUrl + '/cooperate/img' + (i + 1) + '.png');
}
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        mobile: '',
        company_name: '',
        company_introduction: ''
      },
      isSubmit: false,
      part1data: [
        {
          icon: connect.icon1_1,
          title: '高额销售激励，最高可达 55%',
          text: '彩蛋采取多种激励政策，销售分佣+超业绩激励+首签激励，让您实现更高的收入。',
        },
        {
          icon: connect.icon1_2,
          title: '20 项专项支持，直销会销助力业务',
          text: '彩蛋提供产品培训、营销推广、人才建设等近 20 项专项服务与支持，助力您更快获客更早收益。',
        },
        {
          icon: connect.icon1_3,
          title: '10年移动电商行业经验，好产品有保障',
          text: '彩蛋保障百万商家稳定运营，拥有良好的商家口碑和产品体验，优质产品让您赢在起跑线。',
        },
        {
          icon: connect.icon1_4,
          title: '彩蛋官方授权，专属代理权益',
          text: '多终端生态接入，深耕多行业服务市场，实现商业价值共享共赢！',
        },
      ],
      part2data: [
        {
          img: connect.img1,
          title: '公司资质/经营范围',
          text: '彩蛋保障百万商家稳定运营，拥有良好的商家口碑和产品体验，优质产品让您赢在起跑线。',
        },
        {
          img: connect.img2,
          title: '销售团队/销售经验',
          text: '有自己的销售团队，或有成熟销售经验，能够独立或在公司扶持下，完成所在区域的彩蛋产品推广、销售等工作。',
        },
        {
          img: connect.img3,
          title: '客户资源/行业资源',
          text: '具备行业客户资源或当地行业协会/投资协会/外贸协会等机构资源。',
        },
      ],
      logo: logo,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  submit() {
      if (inspectPhone(this.state.form.mobile)) {
        const form = this.state.form;
        this.$axios.post('/Channel/apply', form).then(res => {
          if (res.code == 0) {
            this.setState({
              isSubmit: true,
            });
          } else {
            message.warning(res.msg);
          }
        });
      } else {
        message.warning('请输入正确手机号格式');
      }
  }
  handleChange(event) {
    let form = this.state.form;
    form[event.target.name] = event.target.value;
    this.setState({ form: form });
  }
  close() {
    this.setState({
      isSubmit: false,
    });
    window.location.reload();
  }
  render() {
    return (
      <div className="join">
        <div className="part part1">
          <div className="part_title">与彩蛋携手合作 共享多终端市场红利</div>
          <div className="part_flex" style={{ marginTop: '48px' }}>
            {this.state.part1data.map((item, index) => {
              return (
                <div className="part1_item" key={index}>
                  <img src={item.icon} alt="icon" />
                  <div className="part1_right">
                    <div className="part1_title">{item.title}</div>
                    <div className="part1_text">{item.text}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="part part2">
          <div className="part_title">如何成为彩蛋代理商</div>
          <div className="part_flex" style={{ marginTop: '70px' }}>
            {this.state.part2data.map((item, index) => {
              return (
                <div className="part2_item" key={index}>
                  <img src={item.img} alt="icon" />
                  <div className="part2_bottom">
                    <div className="part2_title">{item.title}</div>
                    <div className="part2_text">{item.text}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="part part3">
          <div className="part_title">合作伙伴</div>
          <div className="part_flex" style={{ marginTop: '74px' }}>
            {this.state.logo.map((item, index) => {
              return (
                <div className="part3_item" key={index}>
                  <img src={item} alt="logo" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="part part4">
          <div className="part_title">申请流程</div>
          <div className="part4_flex" style={{ marginTop: '60px' }}>
            <img src={connect.icon2_1} alt="icon" className="part4_icon1" />
            <div className="part4_text">提交申请</div>
            <img src={plan.icon8} alt="icon" className="part4_icon2 a1" />
            <img src={connect.icon2_2} alt="icon" className="part4_icon1" />
            <div className="part4_text">洽谈合作</div>
            <img src={plan.icon8} alt="icon" className="part4_icon2 a2" />
            <img src={connect.icon2_3} alt="icon" className="part4_icon1" />
            <div className="part4_text">审核入驻</div>
            <img src={plan.icon8} alt="icon" className="part4_icon2 a3" />
            <img src={connect.icon2_4} alt="icon" className="part4_icon1" />
            <div className="part4_text">签署合同</div>
          </div>
        </div>
        <div className="part part5">
          <div className="part_title">申请代理</div>
          <div className="part5_form" style={{ marginTop: '45px' }}>
            <form>
              <div className="part5_input">
                <div className="part5_star">* </div>
                <input type="text" placeholder="客户姓名" name="name" onChange={this.handleChange} />
              </div>
              <div className="part5_input">
                <div className="part5_star">* </div>
                <input type="text" placeholder="联系电话" name="mobile" onChange={this.handleChange} />
              </div>
              <div className="part5_input">
                <div className="part5_star">* </div>
                <input type="text" placeholder="公司名称" name="company_name" onChange={this.handleChange} />
              </div>
              <div className="part5_textarea">
                <div className="part5_star">* </div>
                <textarea placeholder="合作描述" name="company_introduction" onChange={this.handleChange}></textarea>
              </div>
              <div className="part5_submit" onClick={this.submit.bind(this)}>
                <div>提交表单</div>
                <img src={index.jiantou} alt="icon" />
              </div>
            </form>
          </div>
          <div className="message" style={{ display: this.state.isSubmit ? 'flex' : 'none' }}>
            <div className="message_text1">提交成功，我们会尽快与您取得联系!</div>
            <div className="iknow" onClick={this.close.bind(this)}>
              我知道了
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class layout extends Component {
  render() {
    return (
      <div>
        <Banner
          back_img={connect.banner_join}
          title="渠道合作"
          introduction="我们的专业服务人员会尽快与您取得联系"
          issearch={true}
        ></Banner>
        <Main></Main>
      </div>
    );
  }
}
