import React, { Component } from 'react';
import style from './index.module.scss';
import { imgUrl } from '../../../../util/config';
import { cashier } from '../../../../util/image';
let logo = [];
for (let i = 0; i < 18; i++) {
  logo.push(imgUrl + '/cooperate/img' + (i + 1) + '.png');
}
export default class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: logo,
    };
  }
  render() {
    return (
      <div className={style.logo}>
        <img src={cashier.bj02} alt="bj02" className={style.bj} />
        <div className={style.logo_title}>50000+家企业信赖之选</div>
        <div className={style.logo_text}>强劲实力，行业领先，他们都在用</div>
        <div className={style.logo_box}>
          {this.state.logo.map((item, index) => {
            return (
              <div className={style.logo_item} key={index}>
                <img src={item} alt="logo" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
