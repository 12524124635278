import { imgUrl } from './config';
export const index = {
  banner: imgUrl + '/index/banner.png',
  cover1: imgUrl + '/index/cover1.png',
  cover2: imgUrl + '/index/cover2.png',
  cover3: imgUrl + '/index/cover3.png',
  cover4: imgUrl + '/index/cover4.png',
  cover_icon1: imgUrl + '/index/cover_icon1.png',
  cover_icon2: imgUrl + '/index/cover_icon2.png',
  cover_icon3: imgUrl + '/index/cover_icon3.png',
  cover_icon4: imgUrl + '/index/cover_icon4.png',
  farmland_icon: imgUrl + '/index/farmland_icon.png',
  livestock_icon: imgUrl + '/index/livestock_icon.png',
  Aquaticproducts_icon: imgUrl + '/index/Aquaticproducts_icon.png',
  fruitTree_icon: imgUrl + '/index/fruitTree_icon.png',
  farmland_img: imgUrl + '/index/farmland_img.png',
  livestock_img: imgUrl + '/index/livestock_img.png',
  Aquaticproducts_img: imgUrl + '/index/Aquaticproducts_img.png',
  fruitTree_img: imgUrl + '/index/fruitTree_img.png',
  jiantou: imgUrl + '/index/jiantou.png',
  hezuo: imgUrl + '/index/hezuo.png',
  zhiding: imgUrl + '/index/zhiding.png',
  weixin: imgUrl + '/index/weixin.png',
  erweima: imgUrl + '/index/erweima.png',
  dianhua: imgUrl + '/index/dianhua.png',
  tool_icon_kefu: imgUrl + '/index/tool_icon_kefu.png',
  tool_icon_store: imgUrl + '/index/tool_icon_store.png',
  tool_icon_xitong: imgUrl + '/index/tool_icon_xitong.png',
  tool_icon_shouyin: imgUrl + '/index/tool_icon_shouyin.png',
  tool_img: imgUrl + '/index/tool_img.png',
  win_img1: imgUrl + '/index/win_img1.png',
  win_img2: imgUrl + '/index/win_img2.png',
  win_img3: imgUrl + '/index/win_img3.png',
  win_img4: imgUrl + '/index/win_img4.png',
  num_back: imgUrl + '/index/num_back.png',
  travel_back1: imgUrl + '/index/travel_back1.png',
  travel_back2: imgUrl + '/index/travel_back2.png',
  travel_back3: imgUrl + '/index/travel_back3.png',
  travel_back4: imgUrl + '/index/travel_back4.png',
  travel_img1: imgUrl + '/index/travel_img1.png',
  travel_img2: imgUrl + '/index/travel_img2.png',
  travel_img3: imgUrl + '/index/travel_img3.png',
  travel_img4: imgUrl + '/index/travel_img4.png',
  news_line: imgUrl + '/index/news_line.png',
  news_triangle: imgUrl + '/index/news_triangle.png',
  support_back:imgUrl + '/index/support_back.png',
  support_icon1:imgUrl + '/index/support_icon1.png',
  support_icon2:imgUrl + '/index/support_icon2.png',
  support_icon3:imgUrl + '/index/support_icon3.png',
  support_icon4:imgUrl + '/index/support_icon4.png',
  soft_back:imgUrl + '/index/soft_back.png',
  soft_img:imgUrl + '/index/soft_img.png',
};
export const connect = {
  banner: imgUrl + '/connect/banner.png',
  arrow_right: imgUrl + '/connect/arrow_right.png',
  banner_join: imgUrl + '/connect/banner_join.png',
  icon1_1: imgUrl + '/connect/icon1_1.png',
  icon1_2: imgUrl + '/connect/icon1_2.png',
  icon1_3: imgUrl + '/connect/icon1_3.png',
  icon1_4: imgUrl + '/connect/icon1_4.png',
  icon2_1: imgUrl + '/connect/icon2_1.png',
  icon2_2: imgUrl + '/connect/icon2_2.png',
  icon2_3: imgUrl + '/connect/icon2_3.png',
  icon2_4: imgUrl + '/connect/icon2_4.png',
  img1: imgUrl + '/connect/img1.png',
  img2: imgUrl + '/connect/img2.png',
  img3: imgUrl + '/connect/img3.png',
};
export const news = {
  i_banner: imgUrl + '/news/i_banner.png',
  i_arrow_1: imgUrl + '/news/i_arrow_1.png',
  i_arrow_2: imgUrl + '/news/i_arrow_2.png',
  i_arrow_3: imgUrl + '/news/i_arrow_3.png',
  i_arrow_4: imgUrl + '/news/i_arrow_4.png',
  i_arrow_5: imgUrl + '/news/i_arrow_5.png',
  i_arrow_6: imgUrl + '/news/i_arrow_6.png',
  i_time: imgUrl + '/news/i_time.png',
  d_wechat: imgUrl + '/news/d_wechat.png',
  d_weibo: imgUrl + '/news/d_weibo.png',
  d_link: imgUrl + '/news/d_link.png',
  d_logo: imgUrl + '/news/d_logo.png',
  d_message: imgUrl + '/news/d_message.png',
  d_phone: imgUrl + '/news/d_phone.png',
  d_inquiry: imgUrl + '/news/d_inquiry.png',
};
export const plan = {
  banner1: imgUrl + '/plan/banner1.png',
  banner2: imgUrl + '/plan/banner2.png',
  banner3: imgUrl + '/plan/banner3.png',
  banner4: imgUrl + '/plan/banner4.png',
  icon1: imgUrl + '/plan/icon1.png',
  icon2: imgUrl + '/plan/icon2.png',
  icon3: imgUrl + '/plan/icon3.png',
  icon4: imgUrl + '/plan/icon4.png',
  icon5: imgUrl + '/plan/icon5.png',
  icon6: imgUrl + '/plan/icon6.png',
  icon7: imgUrl + '/plan/icon7.png',
  icon8: imgUrl + '/plan/icon8.png',
  icon9: imgUrl + '/plan/icon9.png',
  func: imgUrl + '/plan/func.png',
  func_back: imgUrl + '/plan/func_back.png',
  cover1: imgUrl + '/plan/cover1.png',
  cover2: imgUrl + '/plan/cover2.png',
  cover3: imgUrl + '/plan/cover3.png',
  data_back: imgUrl + '/plan/data_back.png',
  chufang: imgUrl + '/plan/chufang.png',
  rencai: imgUrl + '/plan/rencai.png',
  qingzi: imgUrl + '/plan/qingzi.png',
  tianyuan: imgUrl + '/plan/tianyuan.png',
  func_icon1: require('../image/func/func_icon1.png').default,
  func_icon2: require('../image/func/func_icon2.png').default,
  func_icon3: require('../image/func/func_icon3.png').default,
  func_icon4: require('../image/func/func_icon4.png').default,
  func_icon5: require('../image/func/func_icon5.png').default,
  func_icon6: require('../image/func/func_icon6.png').default,
  func_icon7: require('../image/func/func_icon7.png').default,
  func_icon8: require('../image/func/func_icon8.png').default,
  func_icon9: require('../image/func/func_icon9.png').default,
  func_icon10: require('../image/func/func_icon10.png').default,
  func_icon11: require('../image/func/func_icon11.png').default,
  func_icon12: require('../image/func/func_icon12.png').default,
  func_icon13: require('../image/func/func_icon13.png').default,
  func_icon14: require('../image/func/func_icon14.png').default,
  func_icon15: require('../image/func/func_icon15.png').default,
  func_icon16: require('../image/func/func_icon16.png').default,
  func_icon17: require('../image/func/func_icon17.png').default,
  func_icon18: require('../image/func/func_icon18.png').default,
  func_icon19: require('../image/func/func_icon19.png').default,
  func_icon20: require('../image/func/func_icon20.png').default,
  func_icon21: require('../image/func/func_icon21.png').default,
  func_icon22: require('../image/func/func_icon22.png').default,
  func_icon23: require('../image/func/func_icon23.png').default,
  func_icon24: require('../image/func/func_icon24.png').default,
  case: {
    case_logo1: require('../image/case/case_logo1.png').default,
    case_img1: require('../image/case/case_img1.png').default,
    case_code1: require('../image/case/case_code1.png').default,
    case_logo2: require('../image/case/case_logo2.png').default,
    case_img2: require('../image/case/case_img2.png').default,
    case_code2: require('../image/case/case_code2.png').default,
    case_logo3: require('../image/case/case_logo3.png').default,
    case_img3: require('../image/case/case_img3.png').default,
    case_code3: require('../image/case/case_code3.png').default,
    case_logo4: require('../image/case/case_logo4.png').default,
    case_img4: require('../image/case/case_img4.png').default,
    case_code4: require('../image/case/case_code4.png').default,
    case_logo5: require('../image/case/case_logo5.png').default,
    case_img5: require('../image/case/case_img5.png').default,
    case_code5: require('../image/case/case_code5.png').default,
    case_logo6: require('../image/case/case_logo6.png').default,
    case_img6: require('../image/case/case_img6.png').default,
    case_code6: require('../image/case/case_code6.png').default,
    case_logo7: require('../image/case/case_logo7.png').default,
    case_img7: require('../image/case/case_img7.png').default,
    case_code7: require('../image/case/case_code7.png').default,
    case_logo8: require('../image/case/case_logo8.png').default,
    case_img8: require('../image/case/case_img8.png').default,
    case_code8: require('../image/case/case_code8.png').default,
  },
  component: {
    back: imgUrl + '/plan/component_back.png',
    icon1: imgUrl + '/plan/component_icon1.png',
    icon2: imgUrl + '/plan/component_icon2.png',
    icon3: imgUrl + '/plan/component_icon3.png',
    icon4: imgUrl + '/plan/component_icon4.png',
    icon5: imgUrl + '/plan/component_icon5.png',
    icon6: imgUrl + '/plan/component_icon6.png',
    icon7: imgUrl + '/plan/component_icon7.png',
  },
  platform: {
    back: imgUrl + '/plan/platform_back.png',
    icon1: imgUrl + '/plan/platform_icon1.png',
    icon2: imgUrl + '/plan/platform_icon2.png',
    icon3: imgUrl + '/plan/platform_icon3.png',
    icon4: imgUrl + '/plan/platform_icon4.png',
    icon5: imgUrl + '/plan/platform_icon5.png',
    icon6: imgUrl + '/plan/platform_icon6.png',
    icon7: imgUrl + '/plan/platform_icon7.png',
    icon8: imgUrl + '/plan/platform_icon8.png',
  },
  illustration1: imgUrl + '/plan/illustration1.png',
  illustration2: imgUrl + '/plan/illustration2.png',
  illustration3: imgUrl + '/plan/illustration3.png',
};

export const service = {
  banner: imgUrl + '/service/banner.png',
  icon1: imgUrl + '/service/icon1.png',
  icon2: imgUrl + '/service/icon2.png',
  icon3: imgUrl + '/service/icon3.png',
  img1: imgUrl + '/service/img1.png',
  img2: imgUrl + '/service/img2.png',
  img3: imgUrl + '/service/img3.png',
};
export const member = {
  banner: imgUrl + '/member/banner.png',
  banner2: imgUrl + '/member/banner2.png',
  icon1: imgUrl + '/member/icon1.png',
  icon2: imgUrl + '/member/icon2.png',
  icon3: imgUrl + '/member/icon3.png',
  icon4: imgUrl + '/member/icon4.png',
  icon5: imgUrl + '/member/icon5.png',
  icon6: imgUrl + '/member/icon6.png',
  icon7: imgUrl + '/member/icon7.png',
  icon8: imgUrl + '/member/icon8.png',
  img1: imgUrl + '/member/img1.png',
  img2: imgUrl + '/member/img2.png',
};
export const cashier = {
  banner: imgUrl + '/cashier/banner.png',
  banner2: imgUrl + '/cashier/banner2.png',
  icon1: imgUrl + '/cashier/icon1.png',
  icon2: imgUrl + '/cashier/icon2.png',
  icon3: imgUrl + '/cashier/icon3.png',
  icon4: imgUrl + '/cashier/icon4.png',
  img1: imgUrl + '/cashier/img1.png',
  img2: imgUrl + '/cashier/img2.png',
  img3: imgUrl + '/cashier/img3.png',
  img4: imgUrl + '/cashier/img4.png',
  img5: imgUrl + '/cashier/img5.png',
  arrow1: imgUrl + '/cashier/arrow1.png',
  arrow2: imgUrl + '/cashier/arrow2.png',
  bj01: imgUrl + '/cashier/bj01.png',
  bj02: imgUrl + '/cashier/bj02.png',
  bj03: imgUrl + '/cashier/bj03.png',
  pic01: imgUrl + '/cashier/pic01.png',
  pic02: imgUrl + '/cashier/pic02.png',
  pic03: imgUrl + '/cashier/pic03.png',
  logo: imgUrl + '/cashier/logo.png',
};
