import React, { Component } from "react";
import { news } from "../../util/image";
import './index.scss'
import Banner from "../../components/banner";
import { Pagination } from 'antd';

class Main extends Component {
  render() {
    return (
      <div className="news_main">
        <div className="text1">最新动态</div>
        <Swiper
          handleDetails={(id) => this.props.handleDetails(id)}
        ></Swiper>
        <List
          handleDetails={(id) => this.props.handleDetails(id)}
        ></List>
      </div>
    );
  }
}
var timer;
// 轮播图
class Swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      left: -412,
      news: [],
    };
  }
  componentDidMount() {
    this.getNews();
  }
  componentWillUnmount() {
    this.clearLoop();
    this.setState = () => false;
  }
  getNews() {
    this.$axios
      .post('/WebsiteArticle/list', {
        page: 1,
        rows: 5,
      })
      .then(res => {
        if (res.code == 0) {
          this.setState({
            news: res.result.list,
          });
          this.openLoop();
        }
      });
  }
  openLoop() {
    timer = setInterval(() => {
      this.handleNext();
    }, 4000);
  }
  clearLoop() {
    clearInterval(timer);
    timer = null;
  }
  handleNext() {
    let active = this.state.active;
    if (active < this.state.news.length - 1) {
      active++;
    } else {
      active = 0;
    }
    this.setState({
      left: -824,
    });
    setTimeout(() => {
      this.setState({
        left: -412,
        active: active,
      });
    }, 300);
  }
  handlePrev() {
    let active = this.state.active;
    if (active > 0) {
      active--;
    } else {
      active = this.state.news.length - 1;
    }
    this.setState({
      left: 0,
    });
    setTimeout(() => {
      this.setState({
        left: -412,
        active: active,
      });
    }, 300);
  }
  render() {
    const list = this.state.news;
    const active = this.state.active;
    let showlist = [];
    if (active === 0) {
      let newlist = list.slice(0, 4);
      showlist = list.slice(-1).concat(newlist);
    } else if (active + 4 > list.length) {
      let num = active + 4 - list.length;
      let newlist = list.slice(0, num);
      showlist = list.slice(active - 1).concat(newlist);
    } else {
      showlist = list.slice(active - 1, active + 4);
    }
    const swiperlist = showlist.map(item => (
      <li key={item.id} className="swiper_item" onClick={() => this.props.handleDetails(item.id)} onMouseOver={() => this.clearLoop()} onMouseOut={() => this.openLoop()}>
        <img src={item.thumbImage ? item.thumbImage : news.i_banner} alt="swiperimage" />
        <div className="swiper_text">
          <div className="swiper_title">{item.title}</div>
          <div className="swiper_text2">{item.showSource}</div>
          <div className="swiper_bottom">
            <img src={news.i_time} alt="time" />
            <div>{item.publishTime}</div>
            <img src={news.i_arrow_2} alt="time" />
          </div>
        </div>
      </li>
    ));
    return (
      <div className="swiper">
        <div className="btn" onClick={this.handlePrev.bind(this)}>
          <div className="previmage"></div>
        </div>
        <div className="box">
          <ul className={`swiperlist ${this.state.left === -412 ? '' : 'move'}`} style={{ left: this.state.left + 'px' }}>
            {swiperlist}
          </ul>
        </div>
        <div className="btn" onClick={this.handleNext.bind(this)}>
          <div className="nextimage"></div>
        </div>
      </div>
    );
  }
}

// 列表
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      row: 5,
      total_number: 0,
      news: [],
    };
  }
  componentDidMount() {
    this.getNews();
  }
  getNews() {
    this.$axios
      .post('/WebsiteArticle/list', {
        page: this.state.page,
        rows: this.state.row,
      })
      .then(res => {
        if (res.code == 0) {
          this.setState({
            news: res.result.list,
            total_number: res.result.total_number,
          });
        }
      });
  }
  handlePage(page) {
      console.log(page)
    this.setState({
      page,
    },() => {
        this.getNews();
    });

  }
  itemRender(current, type, originalElement) {
    const total_number = this.state.total_number;
    const row = this.state.row;
    const page = this.state.page;
    let pagenum = total_number % row === 0 ? total_number / row : Math.floor(total_number / row) + 1;
    if (type === 'prev') {
      return (
        <a>
          上一页
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a>
          下一页
        </a>
      );
    }
    return originalElement;
  }
  render() {
    const list = this.state.news;
    const page = this.state.page;
    const listgroup = list.map(item => (
      <li key={item.id} className="list_item" onClick={() => this.props.handleDetails(item.id)}>
        <img src={item.thumbImage ? item.thumbImage : news.i_banner} alt="listimage" />
        <div className="list_center">
          <div className="list_title">{item.title}</div>
          <div className="list_text">{item.showSource}</div>
          <div className="list_bottom">
            <img src={news.i_time} alt="time" />
            <div>{item.publishTime}</div>
          </div>
        </div>
        <div className="list_look">
          查看详情
          <div className="list_icon"></div>
        </div>
      </li>
    ));
    const total_number = this.state.total_number;
    return (
      <div>
        <ul className="list">{listgroup}</ul>
        <Pagination total={total_number} itemRender={this.itemRender.bind(this)} showSizeChanger={false} className="page" current={page} defaultPageSize="5" onChange={page => this.handlePage(page)} />
      </div>
    );
  }
}

export default function layout(props){
    return (
      <div>
        <Banner
          back_img={news.i_banner}
          title="公司动态"
          introduction="了解行业动态，把握前沿进展,一起了解更多有用知识"
        ></Banner>
        <Main handleDetails={(id) =>props.handleDetails(id)}></Main>
      </div>
    );
}
