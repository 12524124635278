import axios from 'axios';
import { Promise } from 'core-js';
import { baseurl } from './config';
let qs = require('qs');
let path = '';
path = baseurl + '/Website';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//设置拦截器
axios.interceptors.request.use(
  config => {
    config.url = path + config.url;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
//响应拦截器
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误';
          break;

        case 401:
          err.message = '未授权，请登录';
          break;

        case 403:
          err.message = '拒绝访问';
          break;

        case 404:
          err.message = '请求地址出错';
          break;

        case 408:
          err.message = '请求超时';
          break;

        case 500:
          err.message = '服务器内部错误';
          break;

        case 501:
          err.message = '服务未实现';
          break;

        case 502:
          err.message = '网关错误';
          break;

        case 503:
          err.message = '服务不可用';
          break;

        case 504:
          err.message = '网关超时';
          break;
        case 505:
          err.message = 'HTTP版本不受支持';
          break;
        default:
      }
    }
    return Promise.reject(err);
  }
);
function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(data))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
export default {
  post: post,
};
