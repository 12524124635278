const defaultState = {
  is_cancelScroll: 0,
};
const actionState = (state = defaultState, action) => {
  switch (action.type) {
    case "setCancelScroll":
      let newState = Object.assign({},state);
      newState.is_cancelScroll = action.data;
      return newState;
    default:
  }
  return state;
};
export default actionState;
