import { plan } from "../../util/image";
const data = {
  func1: [
    {
      name: "会员营销",
      icon: plan.func_icon1,
    },
    {
      name: "商品管理",
      icon: plan.func_icon2,
    },
    {
      name: "直播",
      icon: plan.func_icon3,
    },
    {
      name: "数据分析",
      icon: plan.func_icon4,
    },
    {
      name: "财务管理",
      icon: plan.func_icon5,
    },
    {
      name: "会员管理",
      icon: plan.func_icon6,
    },
    {
      name: "订单管理",
      icon: plan.func_icon7,
    },
    {
      name: "商品评价",
      icon: plan.func_icon8,
    },
    {
      name: "优惠券",
      icon: plan.func_icon9,
    },
    {
      name: "装修模板",
      icon: plan.func_icon10,
    },
    {
      name: "现金红包",
      icon: plan.func_icon11,
    },
    {
      name: "在线客服",
      icon: plan.func_icon12,
    },
  ],
  func2: [
    {
      name: "用户画像",
      icon: plan.func_icon13,
    },
    {
      name: "表单提交",
      icon: plan.func_icon14,
    },
    {
      name: "消息通知",
      icon: plan.func_icon15,
    },
    {
      name: "支付有礼",
      icon: plan.func_icon16,
    },
    {
      name: "商品收藏",
      icon: plan.func_icon17,
    },
    {
      name: "包邮设置",
      icon: plan.func_icon18,
    },
    {
      name: "店铺DIY装修",
      icon: plan.func_icon19,
    },
    {
      name: "会员卡",
      icon: plan.func_icon20,
    },
    {
      name: "用户跟进系统",
      icon: plan.func_icon21,
    },
    {
      name: "多门店管理",
      icon: plan.func_icon22,
    },
    {
      name: "店铺后台管理",
      icon: plan.func_icon23,
    },
    {
      name: "门店店员管理",
      icon: plan.func_icon24,
    },
  ],
  components: [
    {
      name: "轮播组件",
      icon: plan.component.icon1,
    },
    {
      name: "金刚区组件",
      icon: plan.component.icon2,
    },
    {
      name: "优惠券组件",
      icon: plan.component.icon3,
    },
    {
      name: "拼团组件",
      icon: plan.component.icon4,
    },
    {
      name: "直播组件",
      icon: plan.component.icon5,
    },
    {
      name: "商品组件",
      icon: plan.component.icon6,
    },
    {
      name: "资讯组件",
      icon: plan.component.icon7,
    },
  ],
};

export default data;
