import React, { Component } from 'react';
import { news } from '../../util/image';
import copy from 'copy-to-clipboard';
import './details.scss';
import { message } from 'antd';
export default class details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      content: '',
    };
  }
  componentDidMount() {
    this.getDetails(this.props.match.params.id);
  }
  getDetails(id) {
    this.$axios
      .post('/WebsiteArticle/info', {
        id: id,
      })
      .then(res => {
        if (res.code == 0) {
          let data = '';
          if (res.result.content.indexOf('{') != -1) {
            let obj = JSON.parse(res.result.content);
            data = obj.content ? obj.content : obj.fp1w9a6h2o1czk01.detail.content;
          } else {
            data = res.result.content;
          }
          data = data.replace(/_ueditor_page_break_tag_/g, '<hr style="border-top:1px dashed #5C5F66; height:1px"/>');
          this.setState({
            details: res.result,
            content: data,
          });
        }
      });
  }
  copyUrl() {
      copy(window.location.href);
      message.success('复制成功')
  }
  render() {
    const details = this.state.details;
    let arr = details.editors;
    let editor = '';
    for (let i in arr) {
      let str = i == arr.length - 1 ? '' : '，';
      editor += arr[i] + str;
    }
    return (
      <div className="details">
        <div className="d_main">
          <header className="d_main_header">
            首页
            <img src={news.i_arrow_6} alt="arrow" />
            公司动态
            <img src={news.i_arrow_6} alt="arrow" />
            文章详情
          </header>
          <div className="d_box">
            <article>
              <header className="d_box_header">{details.title}</header>
              <div className="d_desc">
                <div style={{ marginRight: '40px' }}>来源：{details.showSource}</div>
                <div style={{ marginRight: '40px' }}>发布时间：{details.publishTime}</div>
                <div>作者：{editor}</div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.state.content }} className="d_article"></div>
              <div className="d_footer">
                <div className="d_footer_text" style={{ flex: '1' }}>
                  本文标签：{details.category_name}
                </div>
                <div className="d_footer_text">分享：</div>
                {/* <img src={news.d_wechat} alt="wechat" onClick={() => this.copyUrl()} /> */}
                <img src={news.d_link} alt="link" onClick={() => this.copyUrl()} />
                {/* <img src={news.d_weibo} alt="weibo" /> */}
              </div>
            </article>
            <div>
              <div className="d_card d_card1">
                <img src={news.d_logo} alt="logo" />
                <div>
                  <div className="d_card1_text1">扫一扫关注</div>
                  <div className="d_card1_text2">彩蛋数字微信公众号</div>
                  <div className="d_card1_text3">了解行业动态，把握前沿进展</div>
                </div>
              </div>
              <img className="d_card" src={news.d_inquiry} alt="inquiry" />
              <div className="d_card d_card2">
                <img src={news.d_phone} alt="phone" />
                <div>
                  <div className="d_card2_text1">热线电话</div>
                  <div className="d_card2_text2">400-2689-388</div>
                  <div className="d_card2_text3">
                    商务合作、售前咨询、技术支持
                    <br />
                    专业客服团队为您及时解答
                  </div>
                </div>
              </div>
              <div className="d_card d_card2">
                <img src={news.d_message} alt="message" />
                <div>
                  <div className="d_card2_text1">邮箱</div>
                  <div className="d_card2_text2">3654624@224.com</div>
                  <div className="d_card2_text3">
                    提供商务合作咨询、售前咨询等
                    <br />
                    服务
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
