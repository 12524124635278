import React, { Component } from "react";
import { service ,news} from "../../../util/image";
import Banner from "../components/banner";
import Apply from "../components/apply";
import { backstagePath } from "../../../util/config";
import "./index.scss";
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  go(){
    window.open(backstagePath)
  }
  render() {
    return (
      <div className="service">
        <div className="part1">
          <div className="part1_title">提升服务价值，从这里开始</div>
          <div className="part1_text">“匠心”打造，注重产品功能打磨</div>
          <div className="part1_flex">
            <div className="flex_item a1">
              <div className="flex_item_left">
                <div className="flex_item_left_text1" style={{ backgroundColor: '#4FA8FB' }} text="功能更全面"></div>
                <div className="flex_item_left_text2">
                  可接入小程序、公众号、H5
                  <br />
                  全渠道部署，实现多样化沟通
                </div>
              </div>
              <img src={service.icon1} alt="icon1" />
            </div>
            <div className="flex_item a2">
              <div className="flex_item_left">
                <div className="flex_item_left_text1" style={{ backgroundColor: '#65D0DA' }} text="管理更高效"></div>
                <div className="flex_item_left_text2">
                  快捷回复、自动分配任务
                  <br />
                  智能化管理，提高20%工作效率
                </div>
              </div>
              <img src={service.icon2} alt="icon2" />
            </div>
            <div className="flex_item a3">
              <div className="flex_item_left">
                <div className="flex_item_left_text1" style={{ backgroundColor: '#FBA04F' }} text="服务更满意"></div>
                <div className="flex_item_left_text2">
                  洞察用户与客服需求
                  <br />
                  针对性沟通，提升服务价值
                </div>
              </div>
              <img src={service.icon3} alt="icon3" />
            </div>
          </div>
        </div>
        <div className="part2" style={{ backgroundColor: '#F8FBFE' }}>
          <div className="part2_flex">
            <div className="part2_flex_textpart">
              <div className="part2_flex_title">
                多渠道部署 <br /> 满足多样化需求
              </div>
              <div className="part2_flex_text">可接入微信公众号、小程序、H5，多渠道部署，实现客服统一回复和处理，以客户为中心的视角，提供一致的客服体验。</div>
              <div className="part2_flex_text">可支持文字、图片、语音、商品、订单、二维码等对话方式，满足用户和客服之间多样化沟通需求。</div>
              <div className="part2_flex_more" onClick={() => this.go()}>
                了解更多
                <div></div>
              </div>
            </div>
            <img src={service.img1} alt="img1" style={{ width: '540px' }} />
          </div>
        </div>
        <div className="part2">
          <div className="part2_flex">
            <img src={service.img2} alt="img2" style={{ width: '576px' }} />
            <div className="part2_flex_textpart">
              <div className="part2_flex_title">
                智能化管理
                <br /> 提高工作效率
              </div>
              <div className="part2_flex_text">可设置自动回复、快捷语回复、客服管理等功能，提升客服工作能力，提高工作效率，让您的用户获得最优质的服务。</div>
              <div className="part2_flex_text">根据客服状态自动分配任务；对会话消息进行排列、筛选。转接会员对话等功能，实现客服高效接待客户，提升用户满意度</div>
              <div className="part2_flex_more" onClick={() => this.go()}>
                了解更多
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="part2" style={{ backgroundColor: '#F8FBFE' }}>
          <div className="part2_flex">
            <div className="part2_flex_textpart">
              <div className="part2_flex_title">
                洞察需求
                <br /> 让运营更全面
              </div>
              <div className="part2_flex_text">可查看会员信息、对话记录等信息，多维度了解客户状态和用户需求，针对性的进行沟通，让运营有效全面。</div>
              <div className="part2_flex_text">商家可查看店铺数据、客服数据等信息，多维度了解客服工作状态，并定制相关工作计划，让运营更全面。</div>
              <div className="part2_flex_more" onClick={() => this.go()}>
                了解更多
                <div></div>
              </div>
            </div>
            <img src={service.img3} alt="img3" style={{ width: '707px' }} />
          </div>
        </div>
      </div>
    );
  }
}

export default class layout extends Component {
  render() {
    return (
      <div>
        <Banner
            plate="5"
          back_img={service.banner}
          title="彩蛋智慧客服系统"
          introduction="连接顾客，高效客服工作台，大幅提升客户满意度"
        ></Banner>
        <Main></Main>
        <Apply></Apply>
      </div>
    );
  }
}
