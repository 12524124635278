import React, { Component } from 'react';
import { cashier } from '../../../util/image';
import Banner from '../components/banner';
import Apply from '../components/apply';
import Logo from '../components/logo';
import style from './index.module.scss';
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className={`wrap-center ${style.industry}`}>
          <div className={style.title}>适用多种行业，想开的店都能用</div>
          <ul>
            <li>
              <img src={cashier.img1} alt="" />
              <div>生活超市</div>
            </li>
            <li>
              <img src={cashier.img2} alt="" />
              <div>生鲜水果</div>
            </li>
            <li>
              <img src={cashier.img3} alt="" />
              <div>母婴用品</div>
            </li>
            <li>
              <img src={cashier.img4} alt="" />
              <div>服装鞋帽</div>
            </li>
            <li>
              <img src={cashier.img5} alt="" />
              <div>餐饮店</div>
            </li>
          </ul>
        </div>
        <div
          className={style.scenes}
          style={{
            background: 'url(' + cashier.bj01 + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div className={style.title}>运用场景</div>
          <ul>
            <li>
              <div className={style.content}>
                <div className={style.name}>前台收银</div>
                <p>采用革新性的网络同步技术，一旦遇到断网停电、火灾、失窃等情况，将开启自动保护机制，状况结束后营业数据通过网络自动同步，再也不用担心意外造成的营业中断和 数据丢失。</p>
              </div>
              <div className={style.img}>
                <img src={cashier.pic01} alt="" />
              </div>
            </li>
            <li>
              <div className={style.img}>
                <img src={cashier.pic02} alt="" />
              </div>
              <div className={style.content}>
                <div className={style.name}>前台收银</div>
                <p>采用革新性的网络同步技术，一旦遇到断网停电、火灾、失窃等情况，将开启自动保护机制，状况结束后营业数据通过网络自动同步，再也不用担心意外造成的营业中断和 数据丢失。</p>
              </div>
            </li>
            <li>
              <div className={style.content}>
                <div className={style.name}>前台收银</div>
                <p>采用革新性的网络同步技术，一旦遇到断网停电、火灾、失窃等情况，将开启自动保护机制，状况结束后营业数据通过网络自动同步，再也不用担心意外造成的营业中断和 数据丢失。</p>
              </div>
              <div className={style.img}>
                <img src={cashier.pic03} alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div className={style.equipment}>
          <div className={style.title}>彩蛋收银系统，满足不同商家需求</div>
          <ul>
            <li>
              <img src={cashier.icon1} alt="" />
              <div className={style.content}>
                <div>自助收银</div>
                <p>全自动化收银，省去排队的烦恼，让你一步到位，从此告别 排队结账，顾客当然更爱来。</p>
              </div>
            </li>
            <li>
              <img src={cashier.icon2} alt="" />
              <div className={style.content}>
                <div>线下核销</div>
                <p>自提订单核销，优惠券核销等。支持银联、支付宝、微信、 和二维码验证，无需排队立即核销。</p>
              </div>
            </li>
            <li>
              <img src={cashier.icon3} alt="" />
              <div className={style.content}>
                <div>手机点单+多设备</div>
                <p>手机分机点单，自动打印小票标签。支持零售、餐饮、服装、 生活服务、母婴，无需单独下载软件。</p>
              </div>
            </li>
            <li>
              <img src={cashier.icon4} alt="" />
              <div className={style.content}>
                <div>支持外接小票机、标签机</div>
                <p>可以设置相应小票机及标签机，随时点单，随时出票， 满足不同开店者对手持设备的需求。</p>
              </div>
            </li>
          </ul>
        </div>
        <div
          className={style.process}
          style={{
            background: 'url(' + cashier.banner2 + ')no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div className={style.title}>接入流程</div>
          <div className={style.stage}>
            <div>第一阶段</div>
            <img src={cashier.arrow2} alt="" />
            <div>第二阶段</div>
            <img src={cashier.arrow2} alt="" />
            <div>第三阶段</div>
            <img src={cashier.arrow2} alt="" />
            <div>第四阶段</div>
          </div>
          <div className={style['stage-name']}>
            <div>
              <img src={cashier.arrow1} alt="" />
              <span>提交信息和需求</span>
            </div>
            <div>
              <img src={cashier.arrow1} alt="" />
              <span>初步洽谈</span>
            </div>
            <div>
              <img src={cashier.arrow1} alt="" />
              <span>合作意向</span>
            </div>
            <div>
              <img src={cashier.arrow1} alt="" />
              <span>调研合作</span>
            </div>
          </div>
          <div className={style['stage-info']}>
            <div>
              <p>提交合作意向 描述项目背景和预算</p>
            </div>
            <div>
              <p>工作人员会在3个 工作日内给您电话沟通情况</p>
            </div>
            <div>
              <p>沟通清楚需求，确定 初步合作意向，签署合作书</p>
            </div>
            <div>
              <p>对于特定需求，为您 配置专属服务团队调研合作</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default class layout extends Component {
  render() {
    return (
      <div>
        <Banner back_img={cashier.banner} title="彩蛋智慧收银系统" introduction="连接顾客，高效收银工作台，大幅提升客户满意度"
          plate="7"></Banner>
        <Main></Main>
        <Logo></Logo>
        <Apply></Apply>
      </div>
    );
  }
}
