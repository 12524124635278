
import "./index.scss";
import Search from "./components/search";
export default function HelpCenter() {
    return (
        <>
            <Search></Search>
            <div className="problem wrap-center">
                <header>
                    <h2>常见问题</h2>
                </header>
                <div className="problem-list">

                </div>
            </div>
        </>
    )
}