import { index } from "../../util/image";
const data = {
  data1: [
    {
      title: "数字农业电商",
      introduction: "助力数字化农业电商新时代",
      icon: index.cover_icon1,
    },
    {
      title: "数字生态农业",
      introduction: "认养农业畜牧水产解决方案",
      icon: index.cover_icon2,
    },
    {
      title: "数字运营工具",
      introduction: "全自动化数字运营工具方案",
      icon: index.cover_icon3,
    },
    {
      title: "数字乡村旅游",
      introduction: "数字化绿色健康乡村旅游",
      icon: index.cover_icon4,
    },
  ],
  data2: [
    {
      title: "3分钟搭建移动商城",
      content:
        "数十种装修组件，上百套行业模板，满足搭建需求，帮助商家快速搭建属于自己的个性化独立商城。\n深挖行业特点 匹配产品功能，提供乡村电子商务解决方案。",
      cover: index.cover1,
      top: '70px'
    },
    {
      title: "系统化管理客户",
      content:
        "通过店铺数据、会员数据、智能标签精准勾勒会员画像，多维度深挖会员价值，快速配置会员权益，分级维护客户，通过会员卡/储值卡实现精准营销。",
      cover: index.cover2,
      top: '70px'
    },
    {
      title: "重新定义收银",
      content:
        "稳定高效的收银设备，支持外接小票机，标签机，IPAD等，让你彻底告别又黑又笨的收银机。\n这一切使用无线连接，大幅提升客户满意度。",
      cover: index.cover3,
      top: '70px'
    },
    {
      title: "高效简洁的客服工具",
      content:
        "可设置自动回复、快捷语回复、客服管理等功能，提升客服工作能力，提高工作效率，赋能私域精细运营，促进客户高效转化发掘线索价值。",
      cover: index.cover4,
      top: '70px'
    },
  ],
  data3: [
    {
      icon: index.farmland_icon,
      name: "农田认养",
      info: {
        img: index.farmland_img,
        englishName: "Farmland adoption",
        introduction:
          "农田认养解决方案，用户可以认领农田，在线领养了以后，农场开始为你养殖。",
        key: { name1: "查看土地：", name2: "选择地块：", name3: "确认订单：" },
        otherinfo1:
          "可查看土地相关信息、实时监控、土壤温湿度、土壤电导率等一系列实时数据",
        otherinfo2: "选择地块、选择租赁周期、选择自己需求的地块服务",
        otherinfo3:
          "添加地块选择种植的种子和配送信息，填写租赁人相关信息，立即支付",
      },
    },
    {
      icon: index.livestock_icon,
      name: "畜牧认养",
      info: {
        img: index.livestock_img,
        englishName: "Animal husbandry adoption",
        introduction:
          "畜牧认养解决方案，用户可以认领鸡、鸭、牛、羊等，在线领养了以后，农场开始为你养殖。",
        key: { name1: "认养选择：", name2: "认养详情：", name3: "认养流程：" },
        otherinfo1: "查看畜牧认养项目信息、认养品种、认养周期、收益、优惠",
        otherinfo2:
          "可查看认养收获、认养流程、认养排名、认养环境、认养问题、认养特色等多个项目介绍",
        otherinfo3:
          "选择认养项目下单支付、农场开始进行养殖、养殖周期到达后配送给用户",
      },
    },
    {
      icon: index.Aquaticproducts_icon,
      name: "水产认养",
      info: {
        img: index.Aquaticproducts_img,
        englishName: "Aquaculture adoption",
        introduction:
          "水产认养解决方案，用户可以认领水域，水域中养殖虾、鱼、螃蟹等等，在线领养了以后，农场开始为你养殖。",
        key: { name1: "基本认养：", name2: "认养日志：", name3: "配送信息：" },
        otherinfo1:
          "可根据项目基本信息、起止时间、认养天数、订购时间、支付金额、认养收获、选择水产认养",
        otherinfo2: "可查看认养日志、定期更新的成长记录形成认养溯源",
        otherinfo3: "可查看配送周期、收货地址、修改配送信息",
      },
    },
    {
      icon: index.fruitTree_icon,
      name: "果树认养",
      info: {
        img: index.fruitTree_img,
        englishName: "Fruit tree adoption",
        introduction:
          "果树认养解决方案，用户可以认领各种各样式的果树，在线领养了以后，农场开始为你养殖。",
        key: { name1: "土地服务：", name2: "提交服务：", name3: "确认订单：" },
        otherinfo1:
          "查看果树作物信息可申请土地工单服务、选择自己想要操作的服务",
        otherinfo2: "选择操作对象、操作服务、填写操作备注、提交工单",
        otherinfo3: "根据提交的工单支付付款，农场根据果树类型开始种植。",
      },
    },
  ],
  data4: [
    {
      name: "田园养老",
      introduction:
        "用户可亲身体验生态绿色产品的制作，设施条件可以满足对不同农产品个性化制作的需求。",
      back_img: index.travel_back1,
      img: index.travel_img1,
    },
    {
      name: "共享厨房",
      introduction:
        "用户可亲身体验生态绿色产品的制作，设施条件可以满足对不同农产品个性化制作的需求。",
      back_img: index.travel_back2,
      img: index.travel_img2,
    },
    {
      name: "亲子旅游",
      introduction:
        "家长带着小孩一起旅行，建构良好的亲子互动关系，亲身体验乡村生活，让让心与心贴得更近!",
      back_img: index.travel_back3,
      img: index.travel_img3,
    },
    {
      name: "体验人文风貌",
      introduction:
        "乡村承载着历史的变迁和时代的风貌。乡村的每一个角落，每一天都有不同的故事。",
      back_img: index.travel_back4,
      img: index.travel_img4,
    },
  ],
  data5:[
    {
      img:index.win_img1,
      title:'区县/乡镇/村委',
      text1:'了解乡村建设进度掌握民生、产 业、文旅生态情况',
      text2:'政策有效下发',
      text3:'掌握乡村生态'
    },
    {
      img:index.win_img2,
      title:'村民',
      text1:'资产增值，收入多元化',
      text2:'土地资产导入',
      text3:'生活品质提升'
    },
    {
      img:index.win_img3,
      title:'商户',
      text1:'产业资源汇聚',
      text2:'产品生态输出',
      text3:'选址、选地、选辅投资'
    },
    {
      img:index.win_img4,
      title:'用户',
      text1:'绿色产业产品',
      text2:'体验乡村人文风貌',
    }
  ]
};

export default data;